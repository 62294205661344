import React, { useState } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import FormLabel from 'global/globalComponents/FormLabel/FormLabel'

import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import ExamCategoryStyles from './YoutubeUserStyles'
import { addYoutubeUser } from 'thunks/youtubeUsers/actions'
import { LoadingButton } from 'global/globalComponents'

const AddNewYoutubeUser = (props) => {
  const { open, onClose } = props
  const classes = ExamCategoryStyles()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const [youtubeUserData, setYoutubeUserData] = useState({
    name: "",
    email:"",
    phone:"",
    password:"",
    role: 40,
    channelId: "",
    channelName:"",
    dateOfJoining:"",
    watchTime:"",
    currentSubscribers:""
  })


  const formInputHandler = (e) => {
    const { value, name } = e.target
    setYoutubeUserData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleButtonClick = () => {



    if (
      !youtubeUserData.name || !youtubeUserData.email || !youtubeUserData.password || !youtubeUserData.phone) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    // var randomNumber = Math.floor(1000 + Math.random() * 10000)

    // let data = {
    //   ...youtubeUserData,
    //   code: `ET-${randomNumber}`,
    //   isActive: true,
    // }

    // setYoutubeUserData(data)
    setLoading(true)

    dispatch(
      addYoutubeUser(youtubeUserData, (res, err) => {
        addYoutubeUserCallback(res, err)
      })
    )
  }

  const addYoutubeUserCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {
        name: "",
        email:"",
        phone:"",
        password:"",
        role: 40,
        channelId: "",
        channelName:"",
        dateOfJoining:"",
        watchTime:"",
        currentSubscribers:""
      }
      setYoutubeUserData(data)

      dispatch(fireSuccessToaster('Exam added successfully!'))
      closeSidePanel()
    }
  }

  const closeSidePanel = () => {
    onClose()
    setYoutubeUserData({
      code: '',
      name: '',
      isActive: false
    })

    // setToggleSwitch(false)
  }

  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={closeSidePanel}>Add Youtube User</DrawerHeader>
        <DrawerContent open={open} style={{marginBottom: "30px"}}>

          <div>
            <Box mt={2} className="flex-1">

              <FormLabel required>Name</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="name"
                type="text"
                placeholder="Youtube Channel Owner Name"
                value={youtubeUserData.name}
                onChange={formInputHandler}
              />
            </Box>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">
                <FormLabel required> Email Id</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="email"
                  type="text"
                  placeholder="Email"
                  value={youtubeUserData.email}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">
                <FormLabel required> Phone Number</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="phone"
                  type="number"
                  placeholder="Phone Number"
                  value={youtubeUserData.phone}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">

                <FormLabel required> Password</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="password"
                  type="text"
                  placeholder="Password"
                  value={youtubeUserData.password}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">

                <FormLabel required> Channel Url</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="channelId"
                  type="text"
                  placeholder="Channel Url"
                  value={youtubeUserData.channelId}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">

                <FormLabel required> Channel Name</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="channelName"
                  type="text"
                  placeholder="Channel Name"
                  value={youtubeUserData.channelName}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">

                <FormLabel required> Date of Joining</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="DateOfJoinig"
                  type="text"
                  placeholder="Date Of Joining"
                  value={youtubeUserData.dateOfJoining}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">
                <FormLabel required> current Watch time</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="watchTime"
                  type="text"
                  placeholder="watch Time"
                  value={youtubeUserData.watchTime}
                  onChange={formInputHandler}
                />
              </Box>
            </div>

            <div className="flex space-x-4">
              <Box mt={2} className="flex-1">

                <FormLabel required> current Subscribers </FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="currentSubscribers"
                  type="text"
                  placeholder="Current Subscribers"
                  value={youtubeUserData.currentSubscribers}
                  onChange={formInputHandler}
                />
              </Box>
            </div>


          </div>

        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewYoutubeUser)
