import axios from 'axios'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { getAuthToken } from 'utils'

import { errorMessages } from 'utils'

import { TOPICS } from './reducers'


export const addTopics = (data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: TOPICS.LOADING })
    try {
      const res = await axios({
        method: 'POST',
        url: '/topic',
        data,

      })
      dispatch({ type: TOPICS.ADD_ONE_TOPICS, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TOPICS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchTopicsBySubjectId = (subjectId, callback) => {
  return async (dispatch) => {
    // dispatch({ type: TOPICS.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/topic/subject/' + subjectId,
        // headers: {
        //   Authorization: getAuthToken()
        // }
      })
      dispatch({ type: TOPICS.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TOPICS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


/**
 * update Teamamte isACtive status. 
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateTopic = (id, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: TOPICS.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/topic/${id}`,
        data
      })
      dispatch({ type: TOPICS.UPDATE_ONE_TOPICS, payload: res.data })
      if (callback) callback(res, false)
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TOPICS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


/**
 * Delete one subject of a user.
 * @param {String} subject subject Id
 * @param {Function} callback  callback function
 */
export const deleteTopic = (subjectId, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/topic/${subjectId}`
      })
      if (callback) callback(res.data, false)

      dispatch({ type: TOPICS.DELETE_ONE_TOPICS, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: TOPICS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


export const resetTopics = (callback) => {
  return async (dispatch) => {
    try {
    
      dispatch({ type: TOPICS.RESET_TOPICS, payload: [] })
      if (callback) callback([], false)
    } catch (err) {
      dispatch({ type: TOPICS.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err.response, true)
    }
  }
}

