import React, { useState } from 'react'
import { Box, Button, TextField, FormLabel } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { OuterContentWrapper, SectionHeading } from 'global/globalComponents'

import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import Select from 'react-select'

import { useLocation } from 'react-router-dom'

import ResourcesStyles from './TestsStyles'
import { fetchAllExam } from 'thunks/exam/actions'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { LoadingButton } from 'global/globalComponents'
import { useEffect } from 'react'
import { fetchSubject } from 'thunks/subject/actions'
import { fetchTopicsBySubjectId, resetTopics } from 'thunks/topic/actions'
import { fetchQuestion } from 'thunks/questions/actions'
import { addTest } from 'thunks/test/actions'

const AddTests = (props) => {
  const { open, onClose, state } = props

  const location = useLocation()
  const classes = ResourcesStyles()
  const dispatch = useDispatch()

  const { categoryId } = location.state
  const [loading, setLoading] = useState(false)
  const [testData, setTestData] = useState({
    title: '',
    icon: '',
    type: 'practice_test',
    banner: '',
    duration: 0,
    isFree: true,
    posMarks: 1,
    negMarks: 0.25,
    language: [
      {
        label: 'English',
        value: 'en'
      }
    ],
    superCategory: [],
    category: [],

    sections: [],
    isActive: false
  })
  const subjectData = useSelector((state) => state.subject)
  const topicsData = useSelector((state) => state.topic)

  const [allCategoryData, setAllCategoryData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectSubject, setSelectSubject] = useState([])
  const [currentSubject, setCurrentSubject] = useState({
    label: '',
    value: '',
    totalQuestions: 5,
    topics: [],
    questions: []
  })

  const languageOptions = [
    {
      label: 'English',
      value: 'en'
    },
    {
      label: 'Hindi',
      value: 'hi'
    }
  ]

  const [hasError, setHasError] = useState({
    error: false,
    message: ''
  })

  useEffect(() => {
    fetchAllExam((res, err) => {
      if (!err) {
        setAllCategoryData(res)
      }
    })
  }, [])

  useEffect(() => {
    if (!subjectData.fetched || !subjectData.data.length) {
      dispatch(fetchSubject())
    }
  }, [subjectData])

  useEffect(() => {
    if (allCategoryData.length) {
      const defaultCategory = allCategoryData.find((i) => i._id === categoryId)
      setSelectedCategory([
        {
          label: defaultCategory.name,
          value: defaultCategory._id,
          isFixed: true
        }
      ])
    }
  }, [allCategoryData])

  useEffect(() => {
    if (currentSubject.label !== '') {
      dispatch(fetchTopicsBySubjectId(currentSubject.value))
    }
  }, [currentSubject])

  const formInputHandler = (e) => {
    const { value, name } = e.target
    setTestData((prevState) => ({
      ...prevState,
      [name]:
        name === 'year'
          ? parseInt(value, 10)
          : name === 'duration'
          ? value * 60000
          : value
    }))
  }

  const handleButtonClick = async () => {
    setLoading(true)

    const finalSection = selectSubject.map((i) => ({
      _id: i.value,
      name: i.label,
      topics: i.topics.map((i) => i.value),
      questions: i?.questions?.map((i) => i._id)
    }))

    const finalCategory = selectedCategory.length
      ? selectedCategory.map((i) => i.value)
      : []

    var finalSuperCategory = []
    await Promise.all(
      finalCategory.map((i) => {
        var d = allCategoryData.find((cat) => i === cat._id)
        if (d) {
          finalSuperCategory = [...finalSuperCategory, ...d.superCategory]
        }
      })
    )

    finalSuperCategory = [...new Set(finalSuperCategory)]

    var finalData = {
      ...testData,
      category: finalCategory,
      superCategory: finalSuperCategory,
      sections: finalSection
    }
    setTestData((prev) => ({
      ...prev,
      category: finalCategory,
      superCategory: finalSuperCategory,
      sections: finalSection
    }))

    finalSection.map((i) => {
      if (!i?.questions?.length) {
        setHasError((prev) => ({
          ...prev,
          error: true,
          message: 'No Questions FOund'
        }))
      }
    })

    if (
      !finalData.superCategory.length ||
      !finalData.category.length ||
      !finalData.sections.length ||
      hasError.error
    ) {
      setLoading(false)
      alert('SuperCategory/Category Missing. Please refresh and try again')
    } else {
      setLoading(false)

      addTest(finalData, (res, err) => {
        addTestCallback(res, err)
      })
    }
  }

  const addTestCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {
        title: '',
        icon: '',
        type: 'practice_test',
        banner: '',
        duration: 0,
        isFree: true,
        posMarks: 1,
        negMarks: 0.25,
        language: [
          {
            label: 'English',
            value: 'en'
          }
        ],
        superCategory: [],
        category: [],

        sections: [],
        isActive: false
      }
      setTestData(data)

      setSelectSubject([])
      setCurrentSubject({
        label: '',
        value: '',
        totalQuestions: 5,
        topics: [],
        questions: []
      })
      setHasError({
        error: false,
        message: ''
      })

      const defaultCategory = allCategoryData.find((i) => i._id === categoryId)
      setSelectedCategory([
        {
          label: defaultCategory.name,
          value: defaultCategory._id,
          isFixed: true
        }
      ])

      dispatch(fireSuccessToaster('Exam added successfully!'))
      closeSidePanel()
    }
  }

  const handleChange = (newVal, e) => {
    const { name, option } = e
    if (name === 'category') {
      setSelectedCategory(newVal)
    }

    if (name === 'section') {
      setCurrentSubject({ ...newVal, topics: [] })
    }

    if (name === 'topics') {
      setCurrentSubject((prev) => ({ ...prev, topics: newVal }))
    }

    if (name === 'language') {
      setTestData((prev) => ({ ...prev, language: newVal }))
    }
  }

  const handleTotalQuestions = (e) => {
    const { value, name } = e.target
    if (name === 'totalQuestions') {
      setCurrentSubject((prev) => ({ ...prev, [name]: value }))
    }
  }

  const handleRemove = (index) => {
    const updatedFaqList = [...selectSubject]
    updatedFaqList.splice(index, 1)
    setSelectSubject(updatedFaqList)
  }

  const handleFetchQuestions = (index) => {
    const updatedFaqList = [...selectSubject]
    const currentIndexData = updatedFaqList[index]

    const query = {
      subject: currentIndexData.value,
      topics: currentIndexData?.topics?.map((i) => i.value) ?? [],
      totalQuestions: currentIndexData.totalQuestions ?? 5,
      language: testData.language.map((i) => i.value) ?? 'en'
    }

    fetchQuestion(query, (res, err) => {
      if (!err) {
        updatedFaqList[index] = {
          ...currentIndexData,
          questions: res
        }
        setSelectSubject(updatedFaqList)
      } else {
        setHasError((prev) => ({
          ...prev,
          error: true,
          message: `Check ${currentIndexData.label} subject. NO QUESTION FOUND, Please retry or remove/add subject `
        }))
      }
    })
  }

  const handleAddMore = () => {
    if (currentSubject.value) {
      setSelectSubject((prev) => [...prev, currentSubject])

      setCurrentSubject({
        label: '',
        value: '',
        totalQuestions: 5,
        topics: [],
        questions: []
      })

      dispatch(resetTopics())
    }
  }

  const closeSidePanel = () => {
    // onClose()
    setTestData({
      name: '',
      superCategory: '',
      category: '',
      year: '',
      stage: ' ',
      shift: '',
      heldOn: '',
      resources: [
        {
          language: 'English',
          name: '',
          url: ''
        },
        {
          language: 'Hindi',
          name: '',
          url: ''
        }
      ],
      isActive: true
    })
  }

  return (
    <OuterContentWrapper>
      <SectionHeading>Add Tests </SectionHeading>

      <div className="flex space-x-6 py-8 bg-white border border-gray-300 shadow p-4 rounded">
        <div className="flex-1">
          {/* title */}
          <div className="flex mb-2 mt-2 text-xs">
            <Box mt={2} mr={2} className="flex-1 ">
              <FormLabel>Title</FormLabel>

              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="title"
                type="text"
                placeholder="Test Title"
                value={testData.title}
                onChange={formInputHandler}
              />
            </Box>

            <Box mt={2} className="flex-1">
              <FormLabel>Language</FormLabel>
              <Select
                options={languageOptions}
                value={testData.language}
                isMulti
                isSearchable={true}
                styles={reactSelectCustomStyles}
                placeholder="Language "
                className="w-full"
                name="language"
                onChange={handleChange}
                // isOptionDisabled={option => option.isFixed}
              />
            </Box>
          </div>

          {/* Duration, Positive and Negative Marks */}
          <div className="flex mb-2 mt-2 text-xs">
            <Box mt={2} mr={3} className="flex-1 ">
              <FormLabel>Test Duration (in Minutes )</FormLabel>

              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="duration"
                type="number"
                placeholder="Duration in minutes"
                value={testData.duration / 60000}
                onChange={formInputHandler}
              />
            </Box>

            <Box mt={2} mr={2} className="flex-1">
              <FormLabel>Postive Marks (Per Question)</FormLabel>

              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="posMarks"
                type="number"
                placeholder="Positive Marks (per Question)"
                value={testData.posMarks}
                onChange={formInputHandler}
              />
            </Box>

            <Box mt={2} className="flex-1">
              <FormLabel>Negative Marks (Per Question)</FormLabel>

              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="negMarks"
                type="number"
                placeholder="Negative Marks (per Question)"
                value={testData.negMarks}
                onChange={formInputHandler}
              />
            </Box>
          </div>

          {/* Exam Category */}
          <div className="flex mb-2 mt-2 text-xs">
            <Box mt={2} className="flex-1">
              <FormLabel>Select Exams (this test is available for)</FormLabel>
              <Select
                options={allCategoryData.map((i) => ({
                  label: i.name,
                  value: i._id
                }))}
                value={selectedCategory}
                isMulti
                isSearchable={true}
                styles={reactSelectCustomStyles}
                placeholder="Category "
                className="w-full"
                name="category"
                onChange={handleChange}
              />
            </Box>
          </div>

          {selectSubject.map((item, index) => (
            <div
              key={index}
              className="flex mb-2 mt-2 text-xs"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Box mt={2} mr={2} className="flex-1">
                <FormLabel>
                  Select Sections (fetch Questions for the test)
                </FormLabel>
                <Select
                  // options={subjectData.data.map(i => ({label: i.name, value: i._id})).filter(obj => {
                  //   return !selectSubject.some(obj2 => obj2.value === obj?.value);
                  // })}
                  isDisabled={true}
                  value={item}
                  // isSearchable={true}
                  styles={reactSelectCustomStyles}
                  placeholder="Subject "
                  className="w-full"
                  name="section"
                  // onChange={handleChange}
                />
              </Box>

              <Box mt={2} mr={2} className="flex-1">
                <FormLabel>
                  Select Topics (Optional) (for Topicwise Question )
                </FormLabel>
                <Select
                  // options={item.topics}
                  isDisabled={true}
                  value={item.topics}
                  isMulti
                  // isSearchable={true}
                  styles={reactSelectCustomStyles}
                  placeholder="Topics "
                  className="w-full"
                  name="topics"
                  // onChange={handleChange}
                />
              </Box>

              <Box mt={2} mr={4} className="flex-1">
                <FormLabel>
                  Total Questions Fetched (default: 5 Questions)
                </FormLabel>

                <TextField
                  disabled
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="totalQuestions"
                  type="number"
                  placeholder="Questions To Be fetched"
                  value={item.totalQuestions ?? 5}
                  onChange={handleTotalQuestions}
                />
              </Box>

              <Box mt={2} className="flex-1 cursor-pointer">
                <div
                  style={{
                    fontSize: '16px',
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </div>
              </Box>

              <Box mt={2} className="flex-1 cursor-pointer">
                <div
                  style={{
                    fontSize: '16px',
                    color: item?.questions?.length ? 'lightblue' : 'red',
                    fontWeight: 'bold'
                  }}
                  isDisabled={item?.questions?.length ? true : false}
                  onClick={() => handleFetchQuestions(index)}
                >
                  {item?.questions?.length
                    ? `${item?.questions?.length} Questions fetched`
                    : 'fetch Questions'}
                </div>
              </Box>
            </div>
          ))}

          <div className="flex mb-2 mt-2 text-xs">
            <Box mt={2} mr={2} className="flex-1">
              <FormLabel>
                Select Sections (fetch Questions for the test)
              </FormLabel>
              <Select
                options={subjectData.data
                  .map((i) => ({ label: i.name, value: i._id }))
                  .filter((obj) => {
                    return !selectSubject.some(
                      (obj2) => obj2.value === obj?.value
                    )
                  })}
                value={currentSubject}
                isSearchable={true}
                styles={reactSelectCustomStyles}
                placeholder="Subject "
                className="w-full"
                name="section"
                onChange={handleChange}
              />
            </Box>

            <Box mt={2} mr={2} className="flex-1">
              <FormLabel>
                Select Topics (Optional) (for Topicwise Question )
              </FormLabel>
              <Select
                options={topicsData.data.map((i) => ({
                  label: i.name,
                  value: i._id
                }))}
                value={currentSubject?.topics ?? []}
                isMulti
                isSearchable={true}
                styles={reactSelectCustomStyles}
                placeholder="Topics "
                className="w-full"
                name="topics"
                onChange={handleChange}
              />
            </Box>

            <Box mt={2} className="flex-1">
              <FormLabel>
                Total Questions Fetched (default: 5 Questions)
              </FormLabel>
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="totalQuestions"
                type="number"
                placeholder="Questions To Be fetched"
                value={currentSubject.totalQuestions ?? 5}
                onChange={handleTotalQuestions}
              />
            </Box>
          </div>

          <p
            className="flex mb-20 mt-10 text-s cursor-pointer"
            style={{
              color: 'blue',
              fontWeight: 'bold'
            }}
            onClick={handleAddMore}
          >
            + Add More
          </p>
          {hasError.error && (
            <p
              className="flex mb-20 mt-20 text-m"
              style={{
                color: 'red',
                fontWeight: 'bold'
              }}
            >
              {hasError.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex mt-2">
        <Button variant="outlined" onClick={closeSidePanel} className="flex-1">
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          onClick={handleButtonClick}
          className="flex-1"
        >
          Add
        </LoadingButton>
      </div>
    </OuterContentWrapper>
  )
}

export default AddTests
