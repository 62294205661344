const initialState = {
  loading: true,
  error: false,
  data: [],
  fetched: false
}

export const RESOURCES = {
  LOADING: 'RESOURCES_LOADING',
  ERROR: 'RESOURCES_ERROR',
  FETCHED: 'RESOURCES_FETCHED',
  UPDATED: 'RESOURCES_UPDATED',
  ADD_ONE_RESOURCES: 'ADD_ONE_RESOURCES',
  UPDATE_ONE_RESOURCES: 'UPDATE_ONE_RESOURCES',
  DELETE_ONE_RESOURCES: 'DELETE_ONE_RESOURCES',
  RESET_RESOURCES:"RESET_RESOURCES"
}

const resourcesReducer = (state = initialState, action) => {

  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case RESOURCES.LOADING:
      return { ...state, loading: true, error: false, fetched: false }

    case RESOURCES.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case RESOURCES.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case RESOURCES.UPDATED:
      return {

        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case RESOURCES.ADD_ONE_RESOURCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }

    case RESOURCES.UPDATE_ONE_RESOURCES:
      const updatedData1 = [...state.data]

      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
     
      return {
        ...state,
        data: updatedData1,
        fetched: true
      }

    case RESOURCES.DELETE_ONE_RESOURCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter((item) => item._id !== action.payload._id),
        fetched: true
    }

    case RESOURCES.RESET_RESOURCES:
      return {
        ...state,
        ...initialState
    }
    default:
      return state
  }
}

export default resourcesReducer
