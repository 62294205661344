import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import AddNewExam from './AddNewExamCategory'
import { PopupMenu } from 'global/globalComponents'

import { ToggleSwitch } from 'global/globalComponents'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'

import Pill from 'global/globalComponents/Pill/Pill'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import userRoles, { getUserPath } from 'utils/userRoles'
import { useHistory } from 'react-router-dom'



import { OuterContentWrapper, SectionHeading } from 'global/globalComponents'

import { errorMessages } from 'utils'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { deleteExamCategory, fetchAllExamCategory, resetExamCategory, updateCurrentSuperCategory, updateExamCategory } from 'thunks/examCategory/actions'
import ExamCategoryEditPanel from './ExamCategoryEditPanel'


import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'
import { format } from 'date-fns'

const ExamCategory = () => {
  const dispatch = useDispatch()

  const examCategoryData = useSelector((state) => state.examCategory)

  const permission = useSelector((state) => state.permission.data)

  const [examAddPanel, setExamAddPanel] = useState(false)
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [loading, setLoading] = useState(false)

  const [teammateData, setTeammateData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [deleteExamCategoryData, setDeleteExamCategoryData] = useState({
    open: false,
    data: {},
    loading: false
  })

  useEffect(() => {
    if (!examCategoryData.fetched) {
      dispatch(fetchAllExamCategory())
    }
  }, [examCategoryData.fetched])


  useEffect(()=>{

    return () => {
      dispatch(resetExamCategory())
    }
  },[])


  const handleDeleteExam = (contact, e) => {
    setLoading(true)
    setDeleteExamCategoryData((prev) => ({ ...prev, loading: true }))

    dispatch(deleteExamCategory(contact._id, deleteExamCategoryallback))
  }

  const deleteExamCategoryallback = (res, err) => {

    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res.data.message))
    } else {
      setLoading(false)
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }


  const handleClose = () => {
    setExamAddPanel(false)
  }

  const handleSidePanel = () => {
    setExamAddPanel(true)
  }

  const handleEditPanelOpen = (item) => {
    if (teammateData.open) {
      setTeammateData({
        open: false,
        data: {}
      })
    } else {
      setTeammateData({
        open: true,
        data: item
      })
    }
  }

  const handleDeleteExamCategory = (teammate) => {
    if (deleteExamCategoryData.open) {
      setDeleteExamCategoryData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteExamCategoryData({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleEditPanelClose = () => {
    setTeammateData({ open: false, data: {} })
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateExamCategory(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }
  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  return (
    <OuterContentWrapper>
      <div>
        <div className="flex justify-between items-center mb-9">
          <SectionHeading>Exam Category</SectionHeading>
          {permission[0]?.examCategory?.create && (
            <Button
              variant="outlined"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleSidePanel}
            >
              Add Exam Category
            </Button>
          )}
        </div>
        <TableContainer mb={9}>
          <Thead>
            <Th className="w-44 whitespace-nowrap">Name</Th>

            {/* <Th className="w-48 whitespace-nowrap">Exams</Th> */}

            <Th className="text-center">Status</Th>

            <Th className="w-48 text-center whitespace-nowrap">Created At</Th>
            {(permission[0]?.examCategory?.update ||
              permission[0]?.examCategory?.delete) && (
                <Th className="whitespace-nowrap text-center">More Options</Th>
              )}
          </Thead>

          <Tbody>
            {examCategoryData.loading ? (
              <TableDataLoading cols={5} />
            ) : examCategoryData.data.length > 0 ? (
              examCategoryData.data.map((item, index) => (
                <Row
                  key={index}
                  item={item}
                  handleEditPanelOpen={handleEditPanelOpen}
                  handleDeleteExam={handleDeleteExam}
                  permission={permission}
                  loading={loading}
                  handleSwitch={handleSwitch}
                  toggleSwitchLoading={toggleSwitchLoading}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan="9"
                  className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
                >
                  There are no exam Category
                </th>
              </Tr>
            )}
          </Tbody>
        </TableContainer>
        <AddNewExam open={examAddPanel} onClose={handleClose} />

        <ExamCategoryEditPanel
          open={teammateData.open}
          onClose={handleEditPanelClose}
          data={teammateData.data}
        />
        <DeleteModal
          warningText=" will be deleted. Do you want to proceed ?"
          open={deleteExamCategoryData.open}
          handleDialog={handleDeleteExamCategory}
          handleDeleteAction={handleDeleteExam}
          loading={deleteExamCategoryData.loading}
        />
      </div>
    </OuterContentWrapper>
  )
}

const Row = ({
  item,
  handleSwitch,
  handleEditPanelOpen,
  handleDeleteExam,
  toggleSwitchLoading,
  permission
}) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const meData = useSelector((state) => state.me.data)

  const OpenSingleClientPage = (e) => {
    
    history.push(`${getUserPath(meData.role)}/super-category/profile/${item._id}`)

    // history.push({
    //   pathname: `${getUserPath(meData.role)}/super-category/profile/${item._id}`,
    //   state: item
    // })
  }

  const openEditTeammatePanel = () => handleEditPanelOpen(item)
  const handleDelete = () => handleDeleteExam(item)

  return (
    <Tr key={item._id}>
      <Td onClick={OpenSingleClientPage} className="whitespace-nowrap text-left cursor-pointer">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.abbreviation}
          </span>
          <span className="text-primary-mid-dark-2 text-xs">{item.name}</span>
        </div>
      </Td>

      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          disabled={!permission[0]?.examCategory?.update}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.updatedAt ? format(new Date(item.updatedAt), 'd MMM p') : '-'}
        </span>
      </Td>

      {(permission[0]?.examCategory.update || permission[0]?.examCategory.delete) && (
        <Td className=" whitespace-nowrap text-center">
          <PopupMenu
            menuItems={[
              {
                label: 'Edit',
                method: openEditTeammatePanel,
                icon: <EditIcon className="text-primary-main" />
              },
              {
                label: 'Delete',
                method: handleDelete,
                icon: <DeleteIcon className="text-error-main" />
              }
            ]}
          />
        </Td>
      )}
    </Tr>
  )
}

export default ExamCategory
