import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button} from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'


import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'
import AddNewTest from './AddTests'

import { PopupMenu } from 'global/globalComponents'

import { useParams } from 'react-router-dom';


import { ToggleSwitch } from 'global/globalComponents'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'

import Pill from 'global/globalComponents/Pill/Pill'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import { OuterContentWrapper, SectionHeading } from 'global/globalComponents'

import { errorMessages } from 'utils'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { deleteResources, fetchResources, updateResources } from 'thunks/resources/actions'
import ResourcesEditPanel from './TestsEditPanel'


import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import { format } from 'date-fns'
import { fetchTestsByCategory } from 'thunks/test/actions'


const Test = () => {
  const dispatch = useDispatch()


  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()



  // const practiceSetData = useSelector((state) => state.resources)


  // const superCategoryData = useSelector(state => state.test)
  // const categoryData = useSelector(state => state.exams)

  const permission = useSelector((state) => state.permission.data)

  const [testsAddPanel, setTestsAddPanel] = useState(false)
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [loading, setLoading] = useState(false)
  

  const [singleTestData, setSingleTestData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [deleteTestData, setDeleteTestData] = useState({
    open: false,
    data: {},
    loading: false
  })


  const handleDeleteTest = (contact, e) => {
    setLoading(true)
    setDeleteTestData((prev) => ({ ...prev, loading: true }))

    dispatch(deleteResources(contact._id, deleteTestCallback))
  }

  const deleteTestCallback = (res, err) => {

    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res.data.message))
    } else {
      setLoading(false)
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }



  const { categoryId } = useParams();


  let body = {...({category: categoryId} ?? {})};

  const [refreshing, setRefreshing] = useState(false);

  const dataLimit = 50;
  const [currentPage, setCurrentPage] = useState(0);

  const [isLastPage, setIsLastPage] = useState(false);

  const [practiceSetData, setPractceSetData] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    fetchTestsByCategory({page: currentPage, limit: dataLimit}, {...body}, (res, err) => {
      if (err) {
        setPractceSetData({loading: false, data: []});
      } else {
        setPractceSetData({loading: false, data: res});
        if (res.length < dataLimit) setIsLastPage(true);

      }
    });
  }, []);

  const onRefresh = () => {
    setRefreshing(true);

    setCurrentPage(0);

    fetchTestsByCategory({page: 0, limit: dataLimit}, {...body}, (res, err) => {
      if (err) {
        setPractceSetData({loading: false, data: []});
      } else {
        setPractceSetData({loading: false, data: res});
        if (res.length < dataLimit) setIsLastPage(true);

      }
    });

    setRefreshing(false);
  };

  const fetchMoreData = () => {
    if (isLastPage) return;
    setCurrentPage(currentPage + 1);
    fetchTestsByCategory(
      {page: currentPage + 1, limit: dataLimit},
      {...body},
      (res, err) => {
        if (!err) {
          if (res.length < dataLimit) setIsLastPage(true);
          setPractceSetData(prev => ({
            ...prev,
            loading: false,
            data: [...prev.data, ...res],
          }));
        } else {
          setPractceSetData(prev => ({
            loading: false,
            data: [...prev.data],
          }));
        }
      },
    );
  };



  const handleClose = () => {
    setTestsAddPanel(false)
  }

  const handleSidePanel = () => {
    setTestsAddPanel(true)
  }

  const handleEditPanelOpen = (item) => {
    if (singleTestData.open) {
      setSingleTestData({
        open: false,
        data: {}
      })
    } else {
      setSingleTestData({
        open: true,
        data: item
      })
    }
  }

  const handleDeleteResourcesFn = (teammate) => {
    if (deleteTestData.open) {
      setDeleteTestData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteTestData({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleEditPanelClose = () => {
    setSingleTestData({ open: false, data: {} })
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateResources(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }
  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  return (
    <OuterContentWrapper>
      <div>
        <div className="flex justify-between items-center mb-9">
          <SectionHeading>Tests</SectionHeading>
          {permission[0]?.test?.create && (
            <Button
              variant="outlined"
              startIcon={<AddIcon fontSize="small" />}

              onClick={()=> history.push({

                pathname: pathname +"/addtest",
                state: {categoryId: categoryId}

              }) }
              // onClick={handleSidePanel}
            >
              Add Tests
            </Button>
          )}
        </div>
        <TableContainer mb={9}>
          <Thead>
            <Th className="w-44 whitespace-nowrap">Name</Th>

            <Th className="text-center">Total Enrolled</Th>

            <Th className="text-center">Highest Score</Th>

            <Th className="text-center">Average Score</Th>

            <Th className="text-center">Lowest Score</Th>


            <Th className="text-center">Max Time (mins.)</Th>

            <Th className="text-center">Min. Time (mins.)</Th>

            <Th className="text-center">Total Student Enrolled</Th>




            <Th className="text-center">Status</Th>

            <Th className="w-48 text-center whitespace-nowrap">Created At</Th>
            {(permission[0]?.test?.update ||
              permission[0]?.test?.delete) && (
                <Th className="whitespace-nowrap text-center">More Options</Th>
              )}
          </Thead>

          <Tbody>
            {practiceSetData.loading ? (
              <TableDataLoading cols={5} />
            ) : practiceSetData.data.length > 0 ? (
              practiceSetData.data.map((item, index) => (
                <Row
                  key={index}
                  item={item}
                  handleEditPanelOpen={handleEditPanelOpen}
                  handleDeleteTest={handleDeleteTest}
                  permission={permission}
                  loading={loading}
                  handleSwitch={handleSwitch}
                  toggleSwitchLoading={toggleSwitchLoading}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan="11"
                  className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
                >
                  There are resources
                </th>
              </Tr>
            )}

            {
              !isLastPage &&(
                  <Tr>
                    <Td colSpan="11" className="text-center bg-black" style={{backgroundColor:"lightgreen", fontWeight: "bold"}}>
                        <div className='cursor-pointer' onClick={fetchMoreData}>Load More..</div>
                    </Td>
                  </Tr>
              )
            }
          </Tbody>
        </TableContainer>

      
        <ResourcesEditPanel
          open={singleTestData.open}
          onClose={handleEditPanelClose}
          data={singleTestData.data}
        />
        <DeleteModal
          warningText=" will be deleted. Do you want to proceed ?"
          open={deleteTestData.open}
          handleDialog={handleDeleteResourcesFn}
          handleDeleteAction={handleDeleteTest}
          loading={deleteTestData.loading}
        />
      </div>
    </OuterContentWrapper>
  )
}

const Row = ({
  item,
  handleSwitch,
  handleEditPanelOpen,
  handleDeleteTest,
  toggleSwitchLoading,
  permission
}) => {
  const openEditTeammatePanel = () => handleEditPanelOpen(item)
  const handleDelete = () => handleDeleteTest(item)

  return (
    <Tr key={item._id}>
      <Td onClick={null} className="whitespace-nowrap text-left cursor-pointer">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.title ?? "Prevous Year Paper"}
          </span>
          
          <span className="text-primary-mid-dark-2 text-xs"><b>Language:</b> {item?.language?.map(item => item.label).join(", ")},  <b>Duration:</b> {item.duration/60000} mins.,    <b>Section:</b> {item.sections.map(item => item.name).join(", ")},</span>
        </div>
      </Td>


      <Td  className="whitespace-nowrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.totalEnrolled ?? 0}
          </span>
        </div>
      </Td>

      <Td  className="whitespace-nowrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {(item.analytics.highestScore ?? 0).toFixed(2)}
          </span>
        </div>
      </Td>

      <Td  className="whitespace-nowrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {(item.analytics.averageScore ?? 0).toFixed(2)}
          </span>
        </div>
      </Td>

      <Td  className="whitespace-nowrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {(item.analytics.lowestScore ?? 0).toFixed(2)}
          </span>
        </div>
      </Td>

    

      <Td  className="whitespace-nowrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {(item.analytics.maxTime/60000 ?? 0).toFixed(2)}
          </span>
        </div>
      </Td>

      <Td  className="whitespace-nowrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {(item.analytics.minTime/60000 ?? 0).toFixed(2)}
          </span>
        </div>
      </Td>

      <Td  className="whitespace-nowrap text-center">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.analytics.totalStudentEnrolled ?? 0}
          </span>
        </div>
      </Td>

      <Td className="whitespace-nowrap">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>
      <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.createdAt ? format(new Date(item.createdAt), 'd MMM p') : '-'}
        </span>
      </Td>

      {(permission[0]?.test.update || permission[0]?.test.delete) && (
        <Td className=" whitespace-nowrap text-center">
          <PopupMenu
            menuItems={[
              {
                label: 'Edit',
                method: openEditTeammatePanel,
                icon: <EditIcon className="text-primary-main" />
              },
              // {
              //   label: 'Settings',
              //   method: OpenSingleClientPage,
              //   icon: <SettingIcon className="text-primary-main" />
              // },
              // {
              //   label: 'Delete',
              //   method: handleDelete,
              //   icon: <DeleteIcon className="text-error-main" />
              // }
            ]}
          />
        </Td>
      )}

    </Tr>
  )
}

export default Test
