import axios from 'axios'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { errorMessages } from 'utils'

// import { getAuthToken } from 'utils'

import { YOUTUBEUSERS } from './reducers'


/**
 * Add one Student
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const addYoutubeUser = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/user/addYoutubeUser',
        data
      })
      if (callback) callback(res, false)
      
      dispatch({ type: YOUTUBEUSERS.ADD_ONE_YOUTUBEUSERS, payload: res.data })

    } catch (err) {
      const errMessage =
        err.response?.data?.message || errorMessages.ERROR_MESSAGE
      dispatch({ type:YOUTUBEUSERS.ERROR, payload: err.response })
      if (callback) callback(errMessage, true)
    }
  }
}
/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchYoutubeUsers = (callback) => {
  return async (dispatch) => {
    // dispatch({ type:YOUTUBEUSERS.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/user/youtubeUser',
      })
      dispatch({ type:YOUTUBEUSERS.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type:YOUTUBEUSERS.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}


/**
 * update student data.
 * @param {String} student studentId
 * @param {Object} data name,workspaces
 * @param {Function} callback callback
 */

export const updateYoutubeUsers = (userId, data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'PUT',
        url: `/user/${userId}`,
        data
      })
      if (callback) callback(res.data, false)

      dispatch({ type:YOUTUBEUSERS.UPDATE_ONE_YOUTUBEUSERS, payload: res.data })
      
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type:YOUTUBEUSERS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}


/**
 * Delete one student of a user.
 * @param {String} student student Id
 * @param {Function} callback  callback function
 */
export const deleteYoutubeUsers = (userId, data, callback) => {
  return async (dispatch) => {
    try {


      if (callback)
        callback(
         "No Valid Permissions to delete this user",
          true
        )
      dispatch({
        type:YOUTUBEUSERS.ERROR,
        payload:"No Valid Permissions to delete this user"
      })
      // const res = await axios({
      //   method: 'DELETE',
      //   url: `/user/${userId}`,
      //   data: data
      // })
      // if (callback) callback(res.data, false)

      // dispatch({ type:YOUTUBEUSERS.DELETE_ONE_YOUTUBEUSERS, payload: res.data })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type:YOUTUBEUSERS.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

