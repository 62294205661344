import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'
import Pill from 'global/globalComponents/Pill/Pill'

import {
  OuterContentWrapper,
  SectionHeading,
  ToggleSwitch,
  Wrapper
} from 'global/globalComponents'

import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'

import { fetchTopicsBySubjectId } from 'thunks/topic/actions'
import { PopupMenu } from 'global/globalComponents'

import { useParams } from 'react-router-dom'

import AddNewTopic from './AddNewTopic'

import { Button } from '@material-ui/core'
import { format } from 'date-fns'

const Topics = () => {
  const dispatch = useDispatch()

  const { subjectId } = useParams()

  const topicData = useSelector((state) => state.topic)

  // const [activeTopicsData, setActiveTopicsData] = useState([])

  const [topicAddPanel, setTopicAddPanel] = useState(false)

  useEffect(() => {
    if (!topicData.fetched) {
      dispatch(fetchTopicsBySubjectId(subjectId))
    }
  }, [topicData.fetched])

  // useEffect(() => {
  //     setActiveTopicsData({ loading: false, data: topicData.data })

  // }, [topicData])

  const handleClose = () => {
    setTopicAddPanel(false)
  }

  const handleSidePanel = () => {
    setTopicAddPanel(true)
  }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-9">
        <SectionHeading>Topics</SectionHeading>
        <Button
          variant="outlined"
          startIcon={<AddIcon fontSize="small" />}
          onClick={handleSidePanel}
        >
          Add New Topics
        </Button>

        {/* <div>Selected Subject Name </div> */}
      </div>
      <TableContainer mb={9}>
        <Thead>
          <Th className="w-44 whitespace-nowrap">Code</Th>
          <Th className="w-44 whitespace-nowrap">Name</Th>
          <Th className="w-44 whitespace-nowrap">Total Questions</Th>

          <Th className="text-center">Status</Th>

          <Th className="text-center">Last Update</Th>

          <Th className="whitespace-nowrap text-center">More Options</Th>
        </Thead>

        <Tbody>
          {topicData.loading ? (
            <TableDataLoading cols={5} />
          ) : topicData.data.length > 0 ? (
            topicData.data.map((item, index) => (
              <Row
                key={index}
                item={item}
                // handleDeleteStudent={handleDeleteStudent}
              />
            ))
          ) : (
            <Tr className="bg-white">
              <th
                colSpan="9"
                className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
              >
                There are no Topics
              </th>
            </Tr>
          )}
        </Tbody>
      </TableContainer>

      <AddNewTopic open={topicAddPanel} onClose={handleClose} />
    </OuterContentWrapper>
  )
}

const Row = ({ item, handleDeleteStudent }) => {
  const handleDelete = () => handleDeleteStudent(item)

  return (
    <Tr key={item._id}>
      <Td onClick={null} className="whitespace-nowrap text-left cursor-pointer">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.code}
          </span>
          {/* <span className="text-primary-mid-dark-2 text-xs">{item.email}</span> */}
        </div>
      </Td>
      <Td className="whitespace-nowrap text-left">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
          {/* <span className="text-primary-mid-dark-2 text-xs">{item.title}</span> */}
        </div>
      </Td>

      <Td className="whitespace-nowrap w-0.5 truncate">
        <span className="text-primary-mid-dark font-medium text-sm">
          {item.totalQuestions}
        </span>

        {/* {item?.exam?.length ? (
          <>
            {item.exam.slice(0, 2).map((exam, index) => (

              <div className={index === 0 ? 'leading-6' : 'mt-2'}>

                {index === 0 ? (
                  <div className="leading-3">
                    <Pill variant="pink">{exam.name}</Pill>
                  </div>
                ) : (
                  <div className="flex items-center leading-3">
                    <Pill variant="blue">{exam.name}</Pill>

                    {item.exam?.length > 2 ? (
                      <Pill
                        variant="black"
                        style={{
                          fontSize: 10,
                          padding: '5px',
                          fontWeight: 'bold'
                        }}
                      >
                        +{item.exam?.length - 2}
                      </Pill>
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <div>-</div>
        )} */}

        {/* <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">
            {item.name}
          </span>
        </div> */}
      </Td>

      <Td className="whitespace-nowrap text-left">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          // onChange={() => handleSwitch(item)}
          // loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>

      <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.updatedAt ? format(new Date(item.updatedAt), 'p') : '-'}
        </span>
      </Td>

      <Td className=" whitespace-nowrap text-center">
        <PopupMenu
          menuItems={[
            {
              label: 'Edit',
              // method: openEditStudentPanel,
              icon: <EditIcon className="text-primary-main" />
            },
            // {
            //   label: 'Settings',
            //   // method: OpenSingleClientPage,
            //   icon: <SettingIcon className="text-primary-main" />
            // },
            {
              label: 'Delete',
              method: handleDelete,
              icon: <DeleteIcon className="text-error-main" />
            }
          ]}
        />
      </Td>
    </Tr>
  )
}

export default Topics
