import axios from 'axios'
// import {
//   fireErrorToaster,
//   fireSuccessToaster
// } from 'thunks/fireToaster/actions'
import { errorMessages } from 'utils'

import { QUESTION } from './reducers'


/**
 * Add one Student
 * @param {object} data name,role,email,password
 * @param {Function} callback callback function
 */
export const addQuestion = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/question',
        data
      })
      if (callback) callback(res, false)

      dispatch({ type: QUESTION.ADD_ONE_QUESTION, payload: res.data })

    } catch (err) {
      const errMessage =
        err.response?.data?.message || errorMessages.ERROR_MESSAGE
      dispatch({ type:QUESTION.ERROR, payload: err.response })
      if (callback) callback(errMessage, true)
    }
  }
}
/**
 * fetch a user
 * @param {Function} callback callback function
 */

export const fetchQuestion = async (query, callback) => {

  // return async (dispatch) => {
  const params = new URLSearchParams(query).toString();

    try {
      const res = await axios({
        method: 'GET',
        url: `/question?${params}`,
      })
      // dispatch({ type:QUESTION.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      // dispatch({ type:QUESTION.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  // }
}


export const resetQuestion = (callback) => {
  return async (dispatch) => {
    try {
      if (callback) callback([], false)
      dispatch({ type:QUESTION.RESET_QUESTION, payload: [] })
    } catch (err) {
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type:QUESTION.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

