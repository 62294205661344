import React, { useEffect,  useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom';

import {
  CustomTabs,
  } from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { ReactComponent as BackArrow } from 'static/svg/long-arrow-left.svg'
import Exams from "components/Exams/Exam"
import { fetchAllExamCategory, resetExamCategory, updateCurrentSuperCategory } from 'thunks/examCategory/actions';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

export default function SuperCategoryProfile() {

  const { superCategoryId } = useParams();
  const { path } = useRouteMatch()

  const location = useLocation();
  const dispatch = useDispatch()

  const superCategoryData = useSelector((state) => state.examCategory)

  const [selectedTab, setSelectedTab] = useState(0)

    const handleTabChange = newVal => {
    setSelectedTab(newVal)
  }



  useEffect(() => {

    return () => {
      dispatch(resetExamCategory())
    }
  }, [])


  useEffect(() => {
    if (!superCategoryData.fetched) {
      dispatch(fetchAllExamCategory((res, err) => {
        if (!err)
          dispatch(updateCurrentSuperCategory(superCategoryId))
      }))
    }

    if (superCategoryData.data.length && !superCategoryData.currentSuperCategory?._id) {
      dispatch(updateCurrentSuperCategory(superCategoryId))
    }
  }, [superCategoryData])

  
  return (
    <OuterContentWrapper>
        <>
          <Link
            to={path.split(`/super-category`)[0] + '/super-category/all'}
            className="text-blue-400 text-smaller 2xl:text-sm hover:underline flex items-center space-x-2"
          >
            <BackArrow
              className="h-6 w-4 text-blue-400 dark:text-dark-light"
              style={{ fill: 'rgba(96, 165, 250, 1)' }}
            />
            <span className="dark:text-dark-light">Back</span>
          </Link>

        
          <Wrapper className="mb-9">
            <div
              className={clsx(
                'text-xs 2xl:text-sm',
              )}
            >
             
              <div>
                <div className="mt-2 grid grid-cols-1  lg:grid-cols-3" style={{
                  fontWeight:"bold",
                }} >
                <img
                  style={{
                    // background: '#0066cc',
                    borderRadius: '20px',
                    width: '150px',
                    height: '90px'
                  }}
                  referrerPolicy="no-referrer"  
                  src= {superCategoryData.currentSuperCategory?.icon}
                  alt="logo"
                />

                <div className='text-xl' >{superCategoryData.currentSuperCategory?.name}</div>
                </div>
              </div>
            </div>
          </Wrapper>
          
          <div>
            <div className="w-full mb-6">
              <CustomTabs
                tabsData={[
                  { label: 'Category', value: 0 },
                  // { label: 'About', value: 1 }
                ]}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
          </div>


            <div>
              {selectedTab === 0 && (
                  <Exams
                    superCategoryId={superCategoryId}
                   />
              )}
            </div>
          
        </>
    </OuterContentWrapper>
  )
}