import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'

import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import { updateUserAcces } from 'thunks/generlAppState/actions'
import SectionHeading from 'global/globalComponents/SectionHeading'
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  useLocation
} from 'react-router'
import { clearAndReplaceBreadCumbs } from 'thunks/breadcrumbs/action'
import userRoles, { getUserPath } from 'utils/userRoles'
import { CustomTabs } from 'global/globalComponents'

import ActiveStudent from './ActiveStudent'
import InactiveStudent from './InactiveStudent'
import BlockedStudent from './BlockedStudent'
import AddNewFaculty from './AddNewStudent'
import { useDispatch, useSelector } from 'react-redux'

import { fetchStudent } from 'thunks/student/actions'

const Student = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { data: me } = useSelector((state) => state.me)

  const permission = useSelector((state) => state.permission.data)[0]

  const [selectedTab, setSelectedTab] = useState(pathname)
  const [studentAddPanel, setStudentAddPanel] = useState(false)

  var userStudent = useSelector((state) => state.student)

  const tabsData = useMemo(() => {
    const arr = [
      { label: 'Student', value: path + '/all' },
      { label: 'Inactive Student', value: path + '/inactive' }
      // { label: 'Blocked Student', value: path + '/blocked' }
    ]
    return arr
  }, [me])

  useEffect(() => {
    if (!userStudent.fetched) {
      dispatch(fetchStudent())
    }
  }, [userStudent.fetched])

  useEffect(() => {
    dispatch(
      clearAndReplaceBreadCumbs([
        {
          name: 'Student',
          link: `${getUserPath(me?.role)}/dashboard`,
          index: 0
        }
      ])
    )
  }, [me])

  useEffect(() => {
    dispatch(updateUserAcces(me.role))
  }, [])

  useEffect(() => {
    setSelectedTab(pathname)
  }, [pathname])

  const handleTabChange = useCallback((newVal) => {
    setSelectedTab(newVal)
    history.push(newVal)
  }, [])

  const handleClose = () => {
    setStudentAddPanel(false)
  }

  const handleSidePanel = () => {
    setStudentAddPanel(true)
  }

  return (
    <OuterContentWrapper>
      <div className="flex justify-between items-center mb-9">
        <SectionHeading>Student</SectionHeading>
        {permission?.student?.create && (
          <Button
            variant="outlined"
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleSidePanel}
          >
            New Student
          </Button>
        )}
      </div>
      <div className="flex justify-between items-center mb-9 ">
        <div className="w-full">
          <CustomTabs
            tabsData={tabsData}
            value={selectedTab}
            onChange={handleTabChange}
          />
        </div>
      </div>

      <Switch>
        <Route exact path={path + '/inactive'}>
          <InactiveStudent />
        </Route>
     
        <Route path={path}>
          <ActiveStudent />
        </Route>
      </Switch>

      <AddNewFaculty open={studentAddPanel} onClose={handleClose} />
    </OuterContentWrapper>
  )
}

export default Student
