import { EXAM } from "thunks/exam/reducers"

const initialState = {
  loading: true,
  error: false,
  fetched: false,
  data: [],
  currentSuperCategory:{}
}

export const EXAMCATEGORY = {
  LOADING: 'EXAMCATEGORY_LOADING',
  ERROR: 'EXAMCATEGORY_ERROR',
  FETCHED: 'EXAMCATEGORY_FETCHED',
  UPDATED: 'EXAMCATEGORY_UPDATED',
  ADD_ONE_EXAMCATEGORY: 'ADD_ONE_EXAMCATEGORY',
  UPDATE_ONE_EXAMCATEGORY: 'UPDATE_ONE_EXAMCATEGORY',
  UPDATE_CURRENT_SUPERCATEGORY:"UPDATE_CURRENT_SUPERCATEGORY",
  DELETE_ONE_EXAMCATEGORY: 'DELETE_ONE_EXAMCATEGORY',
  RESET_EXAMCATEGORY: "RESET_EXAMCATEGORY",
}

const examCategoryReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)
  switch (action.type) {
    case EXAMCATEGORY.LOADING:
      return { ...state, loading: true, error: false, fetched: true }

    case EXAMCATEGORY.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case EXAMCATEGORY.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case EXAMCATEGORY.UPDATED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case EXAMCATEGORY.ADD_ONE_EXAMCATEGORY:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }


      case EXAMCATEGORY.UPDATE_CURRENT_SUPERCATEGORY:
        const tempData = [...state.data]
        const currentData = tempData.find(i => i._id === action.payload._id)

        return {
          ...state,
          currentSuperCategory: currentData
        }

    case EXAMCATEGORY.UPDATE_ONE_EXAMCATEGORY:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        fetched: true,
        data: updatedData1
      }

    case EXAMCATEGORY.DELETE_ONE_EXAMCATEGORY:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }

      case EXAMCATEGORY.RESET_EXAMCATEGORY:
        return {
          ...state,
          ...initialState
        }
    default:
      return state
  }
}

export default examCategoryReducer
