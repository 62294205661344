import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTitle from 'customHooks/useTitle'
import examhub_logo from '../../static/images/examhub_logo.png'

import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded'
import { Link } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'

import { dashboardAnalytics } from 'thunks/dashboard/actions'

import { verifyEmail } from 'thunks/user/actions'

import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import userRoles, { getUserPath, roleName } from 'utils/userRoles'

const YoutubeDashboard = () => {
  const meData = useSelector((state) => state.me)

  const dispatch = useDispatch()

  const [dashboardAnalytic, setDashboardAnalytics] = useState({
    student: [],
    faculty: [],
    teammates: []
  })

  useEffect(() => {
    if (!meData.fetched)
      dispatch(dashboardAnalytics(dashboardAnalyticsCallback))
  }, [meData])

  const dashboardAnalyticsCallback = (res, err) => {
    if (res) setDashboardAnalytics(res)
  }

  useTitle('Dashboard | The Exam HUB', examhub_logo)

  const cards = [
    {
      name: 'Total Students',
      href: `/admin/student/all`,
      count: dashboardAnalytic?.student?.length
    },
    {
      name: 'Total Faculty',
      href: '/admin/faculty/all',
      count: dashboardAnalytic?.faculty?.length
    },
    {
      name: 'Total Admin',
      href: '/admin/teammates/all',
      count: dashboardAnalytic?.teammates?.length
    }
  ]

  const handleEmailVerify = () => {
    if (!meData.data.emailVerified) {
      verifyEmail(verifyEmailCallBack)
    }
  }

  const verifyEmailCallBack = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Please check your email for verification'))
    }
  }
  return (
    <div>
 
      <div className="mt-8">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Overview
          </h2>
          <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
           
           {/* Test */}

           {
                  cards.map((card) => (
                    <div
                      key={card.name}
                      className="bg-white overflow-hidden shadow rounded-lg"
                    >
                      <div className="p-5">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            {/* <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true" /> */}
                          </div>
                          <div className="ml-5 w-0 flex-1">
                            <dl>
                              <dt className="text-sm font-medium text-gray-500 truncate">
                                {card.name}
                              </dt>
                              <dd>
                                <div className="text-lg font-medium text-gray-900">
                                  {card.count}
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-5 py-3">
                        <div className="text-sm">
                          <Link
                            to={card.href}
                            className="font-medium text-cyan-700 hover:text-cyan-900"
                          >
                            View all
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                }
          </div>
        </div>
      </div>
    </div>
  )
}

export default YoutubeDashboard
