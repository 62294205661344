import { Box, Button, FormLabel, TextField } from '@material-ui/core'
import {
  LoadingButton,
  OuterContentWrapper,
  SectionHeading
} from 'global/globalComponents'

import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateCurrentCategory, updateExam } from 'thunks/exam/actions'

const AboutCategory = (props) => {
  const { category } = props

  const dispatch = useDispatch()

  const [newFAQ, setNewFAQ] = useState({ question: '', answer: '' })

  const [loading, setLoading] = useState(false)
  const [faqList, setFaqList] = useState([])

  useEffect(() => {
    setFaqList((prev) => [...prev, ...(category.faq ?? [])])
  }, [])

  const handleAddFAQ = (e) => {
    const { name, value } = e.target

    setNewFAQ((prev) => ({ ...prev, [name]: value }))
  }
  const handleAddMore = () => {
    // setFaqList([...faqList, { question: '', answer: '' }]);

    if (newFAQ.question !== '' && newFAQ.answer !== '') {
      let temp = [...faqList]
      temp.push(newFAQ)

      setFaqList(temp)
      setNewFAQ({ question: '', answer: '' })
    } else {
      dispatch(fireErrorToaster('All fields are required'))
    }
  }

  const handleRemove = (index) => {
    const updatedFaqList = [...faqList]
    updatedFaqList.splice(index, 1)
    setFaqList(updatedFaqList)
  }

  const handleSubmit = () => {
    setLoading(true)
    dispatch(
      updateExam(category._id, { faq: faqList }, (res, err) => {
        setLoading(false)
        if (!err) {
          dispatch(updateCurrentCategory(res._id))
          setFaqList(res.faq)
        }
      })
    )
  }

  return (
    <OuterContentWrapper>
      <SectionHeading>FAQ's </SectionHeading>

      <div className="flex space-x-6 py-8 bg-white border border-gray-300 shadow p-4 rounded">
        <div className="flex-1">
          <div setDefaultOptionclassName="mb-2">
            {faqList.map((faq, index) => (
              <div key={index} className="flex space-x-4 mb-4">
                <div className="flex  flex-1 space-x-4">
                  <Box mt={2} className="flex flex-1 flex-col space-y-5">
                    {/* <FormLabel required>Question</FormLabel> */}
                    <TextField
                      inputProps={{
                        autoComplete: 'off'
                      }}
                      name="question"
                      type="text"
                      placeholder="Question"
                      value={faq.question}
                      // onChange={(event) => handleQuestionChange(event, index)}
                      onChange={handleAddFAQ}
                    />
                  </Box>
                </div>

                <div className="flex-1">
                  <Box mt={2} className="flex flex-1 flex-col space-y-5">
                    {/* <FormLabel required>Answer</FormLabel> */}

                    <TextField
                      inputProps={{
                        autoComplete: 'off'
                      }}
                      name="answer"
                      type="text"
                      placeholder="Answer"
                      value={faq.answer}
                      // onChange={(event) => handleAnswerChange(event, index)}
                      onChange={handleAddFAQ}
                    />
                  </Box>
                </div>
                <div className="flex-1">
                  <Box mt={2} className="flex flex-1 flex-col space-y-5">
                    <button
                      style={{ color: 'red', fontWeight: 'bold' }}
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </button>
                  </Box>
                </div>
              </div>
            ))}

            {/* Add New FAQ */}
            <div className="flex space-x-4 mb-4">
              <div className="flex  flex-1 space-x-4">
                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                  {/* <FormLabel required>Question</FormLabel> */}
                  <TextField
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    name="question"
                    type="text"
                    placeholder="Question"
                    value={newFAQ.question}
                    // onChange={(event) => handleQuestionChange(event, index)}
                    onChange={handleAddFAQ}
                  />
                </Box>
              </div>

              <div className="flex-1">
                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                  {/* <FormLabel required>Answer</FormLabel> */}

                  <TextField
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    name="answer"
                    type="text"
                    placeholder="Answer"
                    value={newFAQ.answer}
                    // onChange={(event) => handleAnswerChange(event, index)}
                    onChange={handleAddFAQ}
                  />
                </Box>
              </div>

              <div className="flex-1 text-wrap">
                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                  <Button
                    style={{
                      backgroundColor: 'lightgreen'
                    }}
                    variant="outlined"
                    onClick={handleAddMore}
                  >
                    New Faculty
                  </Button>
                </Box>
              </div>
            </div>
          </div>

          <div className="space-x-4 flex mt-10">
            <LoadingButton
              loading={loading}
              onClick={handleSubmit}
              className="w-full flex-1"
            >
              Final Submit
            </LoadingButton>
          </div>
        </div>
      </div>
    </OuterContentWrapper>
  )
}

export default AboutCategory
