const initialState = {
  loading: true,
  error: false,
  data: [],
  fetched: false
}

export const YOUTUBEUSERS = {
  LOADING: 'YOUTUBEUSERS_LOADING',
  ERROR: 'YOUTUBEUSERS_ERROR',
  FETCHED: 'YOUTUBEUSERS_FETCHED',
  UPDATED: 'YOUTUBEUSERS_UPDATED',
  ADD_ONE_YOUTUBEUSERS: 'ADD_ONE_YOUTUBEUSERS',
  UPDATE_ONE_YOUTUBEUSERS: 'UPDATE_ONE_YOUTUBEUSERS',
  DELETE_ONE_YOUTUBEUSERS: 'YOUTUBEUSERS_DELETE_ONE_YOUTUBEUSERS'
}

const youtubeUsersReducer = (state = initialState, action) => {

  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case YOUTUBEUSERS.LOADING:
      return { ...state, loading: true, error: false, fetched: false }

    case YOUTUBEUSERS.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case YOUTUBEUSERS.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case YOUTUBEUSERS.UPDATED:
      return {

        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case YOUTUBEUSERS.ADD_ONE_YOUTUBEUSERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: [action.payload, ...state.data],
        fetched: true
      }

    case YOUTUBEUSERS.UPDATE_ONE_YOUTUBEUSERS:
      const updatedData1 = [...state.data]

      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
     
      return {
        ...state,
        data: updatedData1,
        fetched: true
      }

    case YOUTUBEUSERS.DELETE_ONE_YOUTUBEUSERS:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data.filter((item) => item._id !== action.payload._id),
        fetched: true
      }
    default:
      return state
  }
}

export default youtubeUsersReducer
