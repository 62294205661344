import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from "react-router-dom";

import useTitle from 'customHooks/useTitle'
import examhub_logo from '../../static/images/examhub_logo.png'
import { useHistory } from 'react-router-dom'


import { ReactComponent as ChevronRight } from 'static/svg/chevron-right.svg'
import { ReactComponent as CheckCircleFilledIcon } from 'static/svg/check-circle-filled.svg'

import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded'

import { verifyEmail } from 'thunks/user/actions'

import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import userRoles, { getUserPath, roleName } from 'utils/userRoles'
import { addYoutubeAccount, addYoutubeUser, youtubeAuthUrl } from 'thunks/youtube/actions'
import { OuterContentWrapper, Wrapper } from 'global/globalComponents';


const YoutubeDashboard = () => {

  const meData = useSelector((state) => state.me)

  const dispatch = useDispatch()
  const history = useHistory()


  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());

  useEffect(()=>{
    if(queryParams.code){
      addYoutubeAccount({code: queryParams.code, type: "youtube", module:"youtubeData"}, (res,err)=>{
        if(res) history.push("/youtube/dashboard")
      })
    }

  },[queryParams.code])

  const [stepIndex, setStepIndex] = useState(0)
  useTitle('Dashboard | The Exam HUB', examhub_logo)


  const handleEmailVerify = () => {
    if (!meData.data.emailVerified) {
      verifyEmail(verifyEmailCallBack)
    }
  }

  const verifyEmailCallBack = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Please check your email for verification'))
    }
  }
  const stepsData = useMemo(() => {
    const arr = [
      {
        name: 'connectYoutubeData',
        heading: 'Connect Youtube',
        description: 'This Step Provides access to YouTube data, such as videos, playlists, etc.',
        btnText: 'Connect Youtube',
        // isLoading: !gettingStartedTasks.fetched,
        
        isCompleted: meData?.data?.profile?.channelBasicInfo ? true : false,
        // image: connectYoutubeData,
        color: '#3b82f6'
      },
    ]

    return arr
  }, [])


  const handleClick = (name) => {
    if (name === 'connectYoutubeData') {
      youtubeAuthUrl({},(res, err)=>{
        if(res)
          window.open(res, "_self")
        else 
        alert(err)
      })
    }
  }

  return (
    <div>

      {/* <div className="mt-8">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Overview
          </h2>

          
          <StepCard {...stepsData[stepIndex]} onClick={handleClick} />
          <Controls
            stepIndex={stepIndex}
            stepsData={stepsData}
            setStepIndex={setStepIndex}
          />
         
        </div>
      </div> */}


{/* Show getting Started When Youtube is not connected */}
        {
          !meData?.data?.profile?.channelBasicInfo && (
              <div className="mx-auto mt-8" style={{ maxWidth: 992 }}>

                <div className="bg-white rounded border">
                  <header className="py-4 px-4 border-b flex items-center justify-between">
                    <h2 className="font-semibold">Get Started</h2>
                  </header>

                  <div className="mb-4" style={{marginLeft: "20px"}}>These Integrations help you for automations and  By these steps we will authenticate you that you are a valid  user</div>

                  <StepCard {...stepsData[stepIndex]} onClick={handleClick} />
                  <Controls
                    stepIndex={stepIndex}
                    stepsData={stepsData}
                    setStepIndex={setStepIndex}
                  />
                </div>
              </div>
          )
        }

        {/* Youtube Analytics */}

        {
          meData?.data?.profile?.channelBasicInfo &&(
            <OuterContentWrapper>
              <Wrapper className="mb-9 mt-4">
                <div className='text-xs 2xl:text-sm'>

                  <div className="flex-1 max-w-lg">
                    <div className="flex items-center mb-4">
                      <div className="relative">
                      
                        <label htmlFor="user-image-uploadbtn">
                          <img
                            src={
                            meData?.data?.profile?.channelBasicInfo?.thumbnails?.default?.url ??
                                'https://s3.wasabisys.com/assets.clientvenue.com/other/fallbackImage.png'
                            }
                            className="object-contain cursor-pointer"
                            style={{ maxWidth: 130, maxHeight: 60, minWidth: 60 }}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="ml-4">
                      <p className="text-primary-dark font-semibold capitalize text-sm md:text-base 2xl:text-lg flex items-center space-x-1 py-1 show-on-hover-parent dark:text-dark-light2">
                            {/* <span>{companyData.brandName}</span> */}

                            <span>{meData?.data.profile.channelBasicInfo?.channelTitle}</span>   
                          </p>

                        <p className=" text-custom-gray-light-6 dark:text-dark-light">
                          <span>{meData?.data.profile.channelBasicInfo?.customUrl ?? "" }</span>
                        </p>
                      </div>
                    </div>

                    <div className=" show-on-hover-parent">
                      <p className="text-primary-dark font-medium  flex items-center space-x-2 py-1 dark:text-dark-light">
                        <span className="dark:text-dark-light2">
                          About
                        </span>
                      
                      </p>

                      <p className="text-custom-gray-light-6 flex space-x-1 dark:text-dark-light">
                         {meData?.data.profile.channelBasicInfo?.channelDescription}
                      </p>
                    </div>
                  </div>

                  <div className= ' mt-8 flex-1'>
                    <div className="show-on-hover-parent">
                      <p className="text-primary-dark font-medium  flex items-center space-x-2 dark:text-dark-light2">
                        <span>Channel Created On</span>
                      </p>
                      <div className="flex items-center space-x-2">
                         {meData?.data.profile.channelBasicInfo?.publishedAt}
                      </div>
                    </div>

                    {/* 2. Account Manager */}
                    <div className='mt-4'>
                      <p className="text-primary-dark font-medium dark:text-dark-light2">

                        Total Subscriber
                      </p>
                      {meData?.data.profile.channelAccountInfo?.subscriberCount}
                    </div>

                    {/* 3. Currency */}
                    <div className="show-on-hover-parent">
                      <p className="text-primary-dark font-medium dark:text-dark-light2">

                        Total View
                      </p>
                      <p className="text-custom-gray-light-6  flex items-center space-x-2 py-1 dark:text-dark-light">
                        <span>{meData?.data.profile.channelAccountInfo?.viewCount}</span>
                      </p>
                    </div>

                    {/* 4. Tax information */}
                    <div className="show-on-hover-parent">
                      <p
                        className={`text-primary-dark font-medium flex items-center space-x-2 dark:text-dark-light2`}
                      >
                        <span>Videos Count</span>
                      </p>
                      <p className="text-custom-gray-light-6 flex items-center space-x-2 pt-1 pb-3 dark:text-dark-light">
                          <span>
                          {meData?.data.profile.channelAccountInfo?.videoCount}

                          </span>
                          
                        </p>
                    </div>
                  </div>
                </div>
              </Wrapper>
            </OuterContentWrapper>
          )
        }
    </div>
  )
}

export default YoutubeDashboard



const Controls = ({ stepIndex, stepsData, setStepIndex }) => {
  return (
    <div className="flex justify-center mb-8 mt-8">
      <div className="flex space-x-4">
        <button
          onClick={() => setStepIndex(prev => (prev > 0 ? prev - 1 : 0))}
          className={stepIndex === 0 && 'opacity-40 cursor-default'}
        >
          <ChevronRight className="rotate-180" />
        </button>
        {stepsData.map((item, index) => (
          <button
            style={{
              border: index === stepIndex ? '2px solid #7cd77c' : 'none',
              transform: `scale(${index === stepIndex ? 1.25 : 1})`
            }}
            className="inline-block bg-gray-100 w-6 h-6 rounded-full"
            onClick={() => setStepIndex(index)}
          >
            {item.isCompleted && (
              <CheckCircleFilledIcon className="text-green-500 w-full h-full" />
            )}
          </button>
        ))}
        <button
          onClick={() =>
            setStepIndex(prev =>
              prev < stepsData.length - 1 ? prev + 1 : stepsData.length - 1
            )
          }
          className={
            stepIndex === stepsData.length - 1 && 'opacity-40 cursor-default'
          }
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  )
}



const StepCard = ({
  name,
  heading,
  description,
  btnText,
  onClick,
  image,
  isCompleted,
  color
}) => {
  return (
    <div className="p-8">
      <div
        className="flex items-center p-10"
        style={{ backgroundColor: color }}
      >
        <div className="w-1/2 mr-4">
          <h1 className="text-2xl font-semibold mb-2 text-white">{heading}</h1>
          <p className="text-white mb-8 text-sm">{description}</p>
          

          <button
            className="px-4 py-2 rounded bg-white text-sm font-medium"
           
            onClick={() => onClick(name)}
            disabled={isCompleted}
            style={{ color: isCompleted? "white": color,
             ...(isCompleted? {backgroundColor:"green"}: {})
          }}
          >
            {isCompleted? "COMPLETED": btnText}
          </button>
        </div>

        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }}
          className="h-72 w-1/2"
        ></div>
      </div>
    </div>

  )
}
