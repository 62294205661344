import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTitle from 'customHooks/useTitle'
import examhub_logo from '../../static/images/examhub_logo.png'

import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded'
import { Link } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'

import { dashboardAnalytics } from 'thunks/dashboard/actions'

import { verifyEmail } from 'thunks/user/actions'

import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import userRoles, { getUserPath, roleName } from 'utils/userRoles'
import YoutubeDashboard from './YoutubeDashboard'
import AdminDashboard from './AdminDashboard'

const transactions = [
  // {
  //   id: 1,
  //   name: 'Payment to Molly Sanders',
  //   href: '#',
  //   amount: '$20,000',
  //   currency: 'USD',
  //   status: 'success',
  //   date: 'July 11, 2020',
  //   datetime: '2020-07-11'
  // }
]
const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800'
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const UserDashboard = () => {

  const permission = useSelector((state) => state.permission.data)

  const meData = useSelector((state) => state.me)

  const dispatch = useDispatch()

  const [dashboardAnalytic, setDashboardAnalytics] = useState({
    student: [],
    faculty: [],
    teammates: []
  })

  useEffect(() => {
    if (!meData.fetched)
      dispatch(dashboardAnalytics(dashboardAnalyticsCallback))
  }, [meData])

  const dashboardAnalyticsCallback = (res, err) => {
    if (res) setDashboardAnalytics(res)
  }

  useTitle('Dashboard | The Exam HUB', examhub_logo)

  const cards = [
    {
      name: 'Total Students',
      href: `/admin/student/all`,
      count: dashboardAnalytic?.student?.length
    },
    {
      name: 'Total Faculty',
      href: '/admin/faculty/all',
      count: dashboardAnalytic?.faculty?.length
    },
    {
      name: 'Total Admin',
      href: '/admin/teammates/all',
      count: dashboardAnalytic?.teammates?.length
    }
  ]

  const handleEmailVerify = () => {
    if (!meData.data.emailVerified) {
      verifyEmail(verifyEmailCallBack)
    }
  }

  const verifyEmailCallBack = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster('Please check your email for verification'))
    }
  }
  return (
    <div>
      {/* <Alert severity="warning">
        This is Demo Account. Maximum functionalities are missing in this
        Account. Write us on "contact@theexamhub.com" for Fully Functional
        Dashboard. ****We are Tracking Your Basic Information from this Account
        like: Location, IP Address, Mac Address etc.. as mentioned in our
        privacy Policy ****{' '}
      </Alert>

      <br></br>

      <Alert severity="error">
        Unverified Account....!!!! Please Verify Your Email Address for fully
        functional dashboard
      </Alert> */}



  

      {/* Page header */}
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="flex-1 min-w-0">
              <div className="flex items-center">
                <img
                  className="hidden h-16 w-16 rounded-full sm:block"
                  src={meData.data.profileImage}
                  alt=""
                />
                <div>
                  <div className="flex items-center">
                    <img
                      className="h-16 w-16 rounded-full sm:hidden"
                      src={meData.data.profileImage}
                      alt=""
                    />
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      Hello, {meData.data.name}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Company</dt>
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                      {meData.data.role ? roleName[meData.data.role] : ' '}
                    </dd>
                    <dt className="sr-only">Account status</dt>
                    <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                      <VerifiedUserRoundedIcon
                        className={clsx(
                          'flex-shrink-0 mr-1.5 h-5 w-5 ',
                          meData.data.isEmailVerified
                            ? 'text-green-400'
                            : 'text-red-400'
                        )}
                        aria-hidden="true"
                      />
                      {meData?.data.isEmailVerified
                        ? ' Verified account'
                        : 'Not Verified'}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              {!meData?.data.isEmailVerified && (
                <button
                  type="button"
                  onClick={handleEmailVerify}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Email Verify
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {
        meData.data.role === 40 ? (<YoutubeDashboard />) : (<AdminDashboard />)
      }

    </div>
  )
}

export default UserDashboard
