import React, { useState, useEffect } from 'react'
import { Box, Button, TextField } from '@material-ui/core'

import { useDispatch } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import Select from 'react-select'

import { LoadingButton } from 'global/globalComponents'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'
import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'

import YoutubeUserStyles from './ResourcesStyles'

import { updateResources } from 'thunks/resources/actions'


const ResourcesEditPanel = (props) => {
  const { open, onClose, data } = props

  const classes = YoutubeUserStyles()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()


  const [resourceData, setResourceData] = useState({

    name: "",
    superCategory: "",
    category: "",
    year: "",
    stage: "",
    shift: "",
    heldOn: "",
    resources: [
      {
        language: "English",
        name: "",
        url: ""
      },
      {
        language: "Hindi",
        name: "",
        url: ""
      },
    ],
    isActive: true
  })


  useEffect(() => {
    setResourceData((prev) => ({
      ...prev,
      ...data
    }))
  }, [data])

  const formInputHandler = (e) => {

    const { value, name } = e.target

    if (name === "englishFileUrl" || name === "englishFileName") {
      const newResources = [...resourceData.resources];
      newResources[0] = { ...newResources[0], [name === "englishFileUrl" ? "url" : "name"]: value  } //url:`/pdf/ssc-cgl/2022/${value}.pdf`
      
      setResourceData((prevState) => ({ ...prevState, resources: newResources }))

    } else if (name === "hindiFileUrl" || name === "hindiFileName") {
      const newResources = [...resourceData.resources];
      newResources[1] = { ...newResources[1], [name === "hindiFileUrl" ? "url" : "name"]: value };
      setResourceData((prevState) => ({ ...prevState, resources: newResources }))
    }else {
      setResourceData((prevState) => ({ ...prevState, [name]:  name === 'year' || name === 'shift' ? parseInt(value, 10) : value }))

    }
  }

  const resourcesCallback = (res, err) => {
    setLoading(false)

    if (err) dispatch(fireErrorToaster(res))
    else {
      onClose()
      // setExamCategoryData({ name: '' })

      setResourceData({

        name: "",
        superCategory: "",
        category: "",
        year: "",
        stage: "",
        shift: "",
        heldOn: "",
        resources: [
          {
            language: "English",
            name: "",
            url: ""
          },
          {
            language: "Hindi",
            name: "",
            url: ""
          },
        ],
        isActive: true
      })
      dispatch(fireSuccessToaster('Exam Category Updated'))
    }
  }

  const handleUpdateResourceData = () => {
    setLoading(true)

    // const filteredDataSet = {
    //   ...examCategoryData,
    //   name: examCategoryData.name.trim().toLowerCase()
    // }

    // if (!filteredDataSet.name.trim()) {
    //   dispatch(fireErrorToaster('Invalid name value!'))
    //   return
    // }

    dispatch(updateResources(data._id, resourceData, resourcesCallback))
  }



  return (
    <>
      <CustomDrawer
        onClose={onClose}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={onClose}>Update Resource</DrawerHeader>
        <DrawerContent open={open}>
          <div>


            <div className="flex mb-2 mt-2 text-xs">

              <Box mt={2} mr={2} className="flex-1 ">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={resourceData.name}
                  onChange={formInputHandler}
                />
              </Box>

              <Box mt={2} className="flex-1 ">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="heldOn"
                  type="text"
                  placeholder="Held On (DD/MM/YYY)"
                  value={resourceData.heldOn}
                  onChange={formInputHandler}
                />
              </Box>
            </div>



            <div className="flex mb-2 mt-2 text-xs">
              <Box mt={2} mr={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="year"
                  type="number"
                  placeholder="Year"
                  value={resourceData.year}
                  onChange={formInputHandler}
                />
              </Box>
              <Box mt={2} mr={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="shift"
                  type="number"
                  placeholder="Shift"
                  value={resourceData.shift}
                  onChange={formInputHandler}
                />
              </Box>

              <Box mt={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="stage"
                  type="text"
                  placeholder="Stage"
                  value={resourceData.stage}
                  onChange={formInputHandler}
                />
              </Box>
            </div>
            <div className="flex mb-2 mt-2 text-xs">
              <Box mt={2} mr={2} className="flex-1">
                <Select
                  // options={superCategoryOptions}
                  // value={selectedSuperCategory}
                  styles={reactSelectCustomStyles}
                  placeholder="Super Category"
                  className="w-full"
                  name="superCategory"
                  // onChange={handleChange}
                />
              </Box>
              <Box mt={2} className="flex-1">
                <Select
                  // options={categoryOptions}

                  // value={
                  //   selectedCategory
                  // }
                  styles={reactSelectCustomStyles}
                  placeholder="Category "
                  className="w-full"
                  name="category"
                  // onChange={handleChange}
                />
              </Box>
            </div>





            <Box mt={2} className="flex-1">
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="englishFileName"
                type="text"
                placeholder="English File Name"
                value={resourceData.resources[0].name}
                onChange={formInputHandler}
              />
            </Box>

            <Box mt={2} className="flex-1">
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="englishFileUrl"
                type="text"
                placeholder="English File Url"
                value={resourceData.resources[0].url}
                onChange={formInputHandler}
              />
            </Box>


            {/* <Box mt={2} className="flex-1">
                  <TextField
                    className={classes.textFieldStyle}
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    name="hindiFileName"
                    type="text"
                    placeholder="Hindi File Name"
                    value={resourceData.resources[1].name}
                    onChange={formInputHandler}
                  />
                </Box>

                <Box mt={2} className="flex-1">
                  <TextField
                    className={classes.textFieldStyle}
                    inputProps={{
                      autoComplete: 'off'
                    }}
                    name="hindiFileUrl"
                    type="text"
                    placeholder="Hindi File Url"
                    value={resourceData.resources[1].url}
                    onChange={formInputHandler}
                  />
                </Box> */}

          </div>

        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button variant="outlined" onClick={onClose} className="w-full">
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleUpdateResourceData}
            className="w-full"
          >
            Update
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(ResourcesEditPanel)
