import React, { useEffect, useState } from 'react'
import { Button, Chip, FormLabel, Radio, TextField, Box } from '@material-ui/core'

import Select from 'react-select'

import {
    OuterContentWrapper,
    SectionHeading,
} from 'global/globalComponents'
import { LoadingButton } from 'global/globalComponents'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentCategory, updateExam } from 'thunks/exam/actions';


import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'




const ExamInfo = (props) => {
    const {category} = props

    const dispatch = useDispatch()
    const meData =  useSelector(state => state.me.data)
    const [examInfoData, setExamInfoData] = useState({})
    const [loading, setLoading] =  useState(false)

    const [selectedQualification, setSelectedQualification] = useState({})
    const qualificationOptions = [
        {
            label:"10th Pass",
            value:"10pass"
        },

        {
            label:"12th Pass",
            value:"12pass"
        },
        {
            label:"Graduation Pass",
            value:"graduation "
        },
        {
            label:"Post Graduation",
            value:"postGraduation "
        },
    ]
    useEffect(()=>{
        setExamInfoData({...category.examInfo?? {}})
    },[])


    const handleQualificationChange = (newVal)=>{

        // setExamInfoData((oldState) => ({
        //     ...oldState,
        //     qualification: newVal.value
        //   }))

        setSelectedQualification(newVal)
    }

    const formInputHandler = (e) => {
        const { value, name } = e.target
        if(["examDateTo", "examDateFrom","registrationTo", "registrationFrom"].includes(name)){
            setExamInfoData((prevState) => ({ ...prevState, [name]: new Date(value).toISOString() }))
        }else{
            setExamInfoData((prevState) => ({ ...prevState, [name]: value }))
        }

    }


    const handleSubmit = () => {

        const data = {
            ...examInfoData,
             updatedAt: new Date().toISOString(), 
             updatedBy: meData._id
        }
        setLoading(true)
        dispatch(updateExam(category._id, {examInfo: data}, (res, err)=>{

            setLoading(false)
            if(!err){
                dispatch(updateCurrentCategory(res._id))
                setExamInfoData(prev => ({...prev, examInfo: res.examInfo}))
            }
        }))


    }

    return (
        <OuterContentWrapper>
            {/* <SectionHeading>Question </SectionHeading> */}

            <div className="flex space-x-6 py-8 bg-white border border-gray-300 shadow p-4 rounded">
                <div className="flex-1">

                    <div setDefaultOptionclassName="mb-2">

                        {/* Vacancy & Qualification */}
                        <div className="flex space-x-4 mb-4">
                            <div className="flex  flex-1 space-x-4">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Vacancies</FormLabel>
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="vacancies"
                                        type="number"
                                        placeholder="Vacancies"

                                        value={examInfoData.vacancies}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>

                            <div className="flex-1">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Qualifications</FormLabel>
                                    <Select
                                        className="col-span-4 md:col-span-2 text-sm"
                                        placeholder="Qualification"
                                        isSearchable={true}
                                        options={[
                                            {
                                                label:"10th Pass",
                                                value:"10pass"
                                            },
                                    
                                            {
                                                label:"12th Pass",
                                                value:"12pass"
                                            },
                                            {
                                                label:"Graduation Pass",
                                                value:"graduation "
                                            },
                                            {
                                                label:"Post Graduation",
                                                value:"postGraduation "
                                            },
                                        ]}
                                        onChange={handleQualificationChange}
                                        value={selectedQualification}
                                        styles={reactSelectCustomStyles}
                                    />
                                    {/* <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="qualification"
                                        type="text"
                                        placeholder="Qualifications"
                                        value={examInfoData.qualification}
                                        onChange={formInputHandler}
                                    /> */}
                                </Box>
                            </div>
                        </div>


                        {/* Salary */}
                        <div className="flex space-x-4 mb-4">
                            <div className="flex  flex-1 space-x-4">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Salary Start From</FormLabel>
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="salaryFrom"
                                        type="number"
                                        placeholder="Vacancies"

                                        value={examInfoData.salaryFrom}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>

                            <div className="flex-1">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Salary To</FormLabel>

                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="salaryTo"
                                        type="number"
                                        placeholder="Salary to"
                                        value={examInfoData.salaryTo}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>
                        </div>

                        {/* Registration */}
                        <div className="flex space-x-4 mb-4">
                            <div className="flex  flex-1 space-x-4">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Registration Start From</FormLabel>
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="registrationFrom"
                                        type="date"
                                        placeholder="Vacancies"

                                        value={examInfoData.registrationFrom ? moment(examInfoData.registrationFrom).format('YYYY-MM-DD') : ''}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>

                            <div className="flex-1">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Registration To</FormLabel>

                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="registrationTo"
                                        type="date"
                                        placeholder="Registration to"
                                        value={examInfoData.registrationTo ? moment(examInfoData.registrationTo).format('YYYY-MM-DD') : ''}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>
                        </div>


                        {/* Exam Date */}
                        <div className="flex space-x-4 mb-4">
                            <div className="flex  flex-1 space-x-4">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Exam Start From</FormLabel>
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="examFrom"
                                        type="date"
                                        placeholder="Exam Start From"

                                        value={examInfoData.examFrom ? moment(examInfoData.examFrom).format('YYYY-MM-DD') : ''}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>

                            <div className="flex-1">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Exam End To</FormLabel>

                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="examDateTo"
                                        type="date"
                                        placeholder="Exams End"
                                        value={examInfoData.examDateTo ? moment(examInfoData.examDateTo).format('YYYY-MM-DD') : ''}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>
                        </div>


                        {/* Official Notification */}
                        <div className="flex space-x-4 mb-4">
                            <div className="flex  flex-1 space-x-4">
                                <Box mt={2} className="flex flex-1 flex-col space-y-5">
                                    <FormLabel required>Official Notification Link</FormLabel>
                                    <TextField
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                        name="officialNotificationLink"
                                        type="text"
                                        placeholder="Official Notification Link"
                                        value={examInfoData.officialNotificationLink}
                                        onChange={formInputHandler}
                                    />
                                </Box>
                            </div>

                        </div>



                    </div>

                    <div className="space-x-4 flex mt-6">
                        <LoadingButton
                            loading={loading}
                            onClick={handleSubmit}
                            className="w-full flex-1"
                        >
                            Update
                        </LoadingButton>
                    </div>
                </div>
            </div >




        </OuterContentWrapper >
    )
}

export default ExamInfo
