import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, TextField, makeStyles } from '@material-ui/core'

import {
  OuterContentWrapper,
  SectionHeading,
} from 'global/globalComponents'

import FormLabel from 'global/globalComponents/FormLabel/FormLabel'

import { LoadingButton } from 'global/globalComponents'
import { createNotification } from 'thunks/me/actions'
import { useDispatch } from 'react-redux'
import { fireErrorToaster, fireSuccessToaster } from 'thunks/fireToaster/actions'




const Notification = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [notificationData, setNotificationData] = useState({
    title:"",
    body:"",
    users:[]
  })

  const formInputHandler = (e) => {
    const { value, name } = e.target
    setNotificationData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleButtonClick = () => {
    if (
      !notificationData.title || !notificationData.body) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }
    setLoading(true)
      createNotification(notificationData, (res, err) => {
        addExamCategoryCallback(res, err)
      })
    
  }

  const addExamCategoryCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {
        title:"",
        body:"",
        users:[]
      }
      setNotificationData(data)

      dispatch(fireSuccessToaster('Notification successfully sent to all users!'))
    }
  }



  return (
    <OuterContentWrapper>
      <SectionHeading>Notification </SectionHeading>

      <div className="flex space-x-6 py-8 bg-white border border-gray-300 shadow p-4 rounded">
        <div className="flex-1">

          <Box mt={2}>
              <FormLabel required>Title</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="title"
                type="text"
                placeholder="Title"
                value={notificationData.title}
                onChange={formInputHandler}
              />
            </Box>


            <Box mt={2}>
              <FormLabel required>Body</FormLabel>
      
            <TextField
                            className={classes.textFieldStyle}

                inputProps={{
                  autoComplete: 'off'
                }}
                placeholder="Notification Body"
                multiline
                rows={5}
                name="body"
                value={notificationData.body}
                onChange={formInputHandler}
              />
            </Box>


         

          <div className="space-x-4 flex mt-6">
            <LoadingButton
              loading={loading}
              onClick={handleButtonClick}
              className="flex-1"
            >
             Send Notification
            </LoadingButton>
          </div>
        </div>
      </div >


    </OuterContentWrapper >
  )
}


const useStyles = makeStyles({
  textFieldStyle: {
    width: '100%'
  }
})

export default Notification
