import axios from 'axios'
import { EXAMCATEGORY } from './reducers'
import { errorMessages } from 'utils'

export const addExamCategory = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/supercategory',
        data
      })
      dispatch({ type: EXAMCATEGORY.ADD_ONE_EXAMCATEGORY, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: EXAMCATEGORY.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

export const fetchAllExamCategory = (callback) => {
  return async (dispatch) => {
    // dispatch({ type: STUDENT.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/supercategory'
      })

      dispatch({ type: EXAMCATEGORY.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: EXAMCATEGORY.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}


export const updateCurrentSuperCategory = (id, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: EXAMCATEGORY.UPDATE_CURRENT_SUPERCATEGORY, payload: {_id: id} })
      if (callback) callback(id, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: EXAMCATEGORY.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}

/**
 * update Teamamte isACtive status.
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateExamCategory = (id, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: EXAMCATEGORY.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/supercategory/${id}`,
        data
      })
      dispatch({ type: EXAMCATEGORY.UPDATE_ONE_EXAMCATEGORY, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      // dispatch({type: , payload:err.response.data.message})
      if (callback) callback(err.response, true)
    }
  }
}

export const deleteExamCategory = (examCategory, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `/examcategory/${examCategory}`
      })

      dispatch({ type: EXAMCATEGORY.DELETE_ONE_EXAMCATEGORY, payload: res.data })
      if (callback) callback(res, false)
    } catch (err) {
      dispatch({ type: EXAMCATEGORY.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err.response, true)
    }
  }
}

export const resetExamCategory = (callback) => {
  return async (dispatch) => {
    try {
    
      dispatch({ type: EXAMCATEGORY.RESET_EXAMCATEGORY, payload: [] })
      if (callback) callback([], false)
    } catch (err) {
      dispatch({ type: EXAMCATEGORY.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err.response, true)
    }
  }
}