import { Button } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// import {
//   Switch,
//   Route,
//   // useRouteMatch,
//   useHistory,


// } from 'react-router'

import {
  fireErrorToaster,
  fireSuccessToaster,
  fireWarningToaster
} from 'thunks/fireToaster/actions'
import {
  ColoredAvatars,
  CustomTooltip,
  CustomTabs,
  Card
} from 'global/globalComponents'
import OuterContentWrapper from 'global/globalComponents/ContentWrapper/OuterContentWrapper'
import Wrapper from 'global/globalComponents/Wrapper/Wrapper'
import clsx from 'clsx'


import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  Link 
} from 'react-router-dom';


import { ReactComponent as BackArrow } from 'static/svg/long-arrow-left.svg'
import { ReactComponent as AngleIcon } from 'static/svg/angle-down.svg'


import Exams from "components/Exams/Exam"
import Resources from "components/Resources/Resources"
import Test from './Tests/Tests'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategoryBySuperCategory, resetExam, updateCurrentCategory } from 'thunks/exam/actions'
import { resetResources } from 'thunks/resources/actions'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ExamInfo from './ExamInfo/ExamInfo'
import AboutCategory from './AboutCategory/AboutCategory'

import AddTests from "./Tests/AddTests"

export default function CategoryProfile() {


  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const history = useHistory()

  const categoryData = useSelector(state => state.exams)

  const {superCategoryId, categoryId} =  useParams()

  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState(0)


  useEffect(() => {

    return () => {
      dispatch(resetExam())
      dispatch(resetResources())

    }
  }, [])

  const tabsData = useMemo(() => {

    const arr = [
      { value: 0, label: 'Resources', value1: pathname },
      { value: 1, label: 'Practice Tests', value1: pathname + "/practice_test" },
      { value: 2, label: 'Test Series', value1: pathname + "/test_series" },
      { value: 3, label: 'Exam Info', value1: pathname + "/exam_info" },
      { value: 4, label: 'Blogs', value1: pathname + "/blogs" },
      { value: 5, label: 'About', value1: pathname + "/about" }
    ]

    return arr
  }, [])



  useEffect(() => {
    if (!categoryData.fetched) {
      dispatch(fetchCategoryBySuperCategory(superCategoryId,{page: 0, limit: 200} ,(res, err) => {
        if (!err)
          dispatch(updateCurrentCategory(categoryId))
      }))
    }

    if (categoryData.data.length && !categoryData.currentCategory?._id) {
      dispatch(updateCurrentCategory(categoryId))
    }
  }, [categoryData])



  // useEffect(() => {
  //   setSelectedTab(pathname)
  // }, [pathname])

  const handleTabChange = useCallback((newVal) => {
    setSelectedTab(newVal)
  }, [])
 
  
  return (
    <OuterContentWrapper>
        <>
          <Link
            to={{
              pathname: path.split(`/category`)[0] + '/super-category/profile/'+ superCategoryId,
              state: {_id: superCategoryId}
            }}
            className="text-blue-400 text-smaller 2xl:text-sm hover:underline flex items-center space-x-2">
            
            <BackArrow
              className="h-6 w-4 text-blue-400 dark:text-dark-light"
              style={{ fill: 'rgba(96, 165, 250, 1)' }}
            />
            <span className="dark:text-dark-light">Back</span>
          </Link>
          <Wrapper className="mb-9 mt-4">
            <div
              className={clsx(
                'text-xs 2xl:text-sm',
              )}
            >
             <div>
                <div className="mt-2 grid grid-cols-1  lg:grid-cols-3" style={{
                  fontWeight:"bold",
                }} >
                <img
                  style={{
                    // background: '#0066cc',
                    borderRadius: '20px',
                    width: '150px',
                    height: '90px'
                  }}
                  referrerPolicy="no-referrer"  
                  src= {categoryData.currentCategory.icon}
                  alt="logo"
                />
                <div className='text-xl' >{categoryData.currentCategory.name}</div>
                </div>
              </div>
            </div>
          </Wrapper>
          <div>
            <div className="w-full mb-6">
              <CustomTabs
                tabsData={tabsData}
                value={selectedTab}
                onChange={handleTabChange}
              />
            </div>
          </div>

          {selectedTab === 0 && (<Resources />)}
          {selectedTab === 1 && (<Test />)}
          {selectedTab === 3 && (<ExamInfo category={categoryData.currentCategory}/>)}
          {selectedTab === 5 && (<AboutCategory category={categoryData.currentCategory}/>)}

        {/* <Switch>
          <Route exact path={path}>
          </Route>
          <Route path={`${path}/practice_test`}>
            <Test />
          </Route>

          <Route path={`${path}/exam_info`}>
            <ExamInfo category={categoryData.currentCategory}/>
          </Route>

          <Route path={`${path}/about`}>
            <AboutCategory category={categoryData.currentCategory}/>
          </Route>
        </Switch> */}


        <Switch >
          <Route path={pathname + '/addtest'}>
            <AddTests categoryId={"123"} />
          </Route>
        </Switch>
        </>

    </OuterContentWrapper>
  )
}