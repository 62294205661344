import React, { useEffect, useState } from 'react'
import { ReactComponent as Home } from 'static/svg/home.svg'
import { ReactComponent as PeopleIcon } from 'static/svg/people.svg'
import { ReactComponent as UsersIcon } from 'static/svg/users.svg'
import { ReactComponent as UserFriendIcon } from 'static/svg/user-friends.svg'
import { ReactComponent as PhoneAltIcon } from 'static/svg/phone-alt.svg'
import { ReactComponent as SettingIcon } from 'static/svg/setting.svg'
import { ReactComponent as BookIcon } from 'static/svg/book.svg'

import { ReactComponent as CategoryIcon } from 'static/svg/category.svg'

import { Role } from 'utils/userRoles'

import { useSelector } from 'react-redux'

export const SideDrawerOptions = () => {
  const meData = useSelector((state) => state.me.data)

  const permission = {
    dashboard: true,
    faculty: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER
    ].includes(meData.role),

    examCategory: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER,
      Role.YOUTUBEUSERS
    ].includes(meData.role),

    exam: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER,
      // Role.YOUTUBEUSERS
    ].includes(meData.role),

    student: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER
    ].includes(meData.role),

    youtube: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER
    ].includes(meData.role),

    team: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER
    ].includes(meData.role),

    resources: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER,
      // Role.YOUTUBEUSERS

    ].includes(meData.role),

    subject: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER
    ].includes(meData.role),

    question: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER
    ].includes(meData.role),

    notification: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
    ].includes(meData.role),

    contactus: [
      Role.USER_PRO_ADMIN,
      Role.ADMIN,
      Role.ADMIN_MANAGER,
      Role.ADMIN_VIEWER
    ].includes(meData.role),

    settings: true
  }

  return {
    mainMenu: [
      {
        name: 'Dashboard',
        icon: <Home className="w-5 h-5" />,
        link: '/dashboard',
        activeNavString: '/dashboard',
        subOptions: [],
        permission: permission.dashboard
      },
      {
        name: 'Super Category',
        icon: <CategoryIcon className="w-5 h-5" />,
        link: '/super-category/all',
        activeNavString: '/super-category',
        subOptions: [],
        permission: permission.examCategory
      },

      // {
      //   name: 'Section',
      //   icon: <SectionIcon className="w-5 h-5" />,
      //   link: '/section/all',
      //   activeNavString: '/section',
      //   subOptions: [],
      //   permission: permission.section
      // },
      // {
      //   name: 'Category',
      //   icon: <CategoryIcon className="w-5 h-5" />,
      //   link: '/exam/all',
      //   activeNavString: '/exam',
      //   subOptions: [],
      //   permission: permission.exam
      // },
      {
        name: 'Faculty',
        icon: <PeopleIcon className="w-5 h-5" />,
        link: '/faculty/all',
        activeNavString: '/faculty',
        subOptions: [
          {
            name: 'Faculty',
            to: '/faculty/all'
          }
        ],
        permission: permission.faculty
      },
      {
        name: 'Student',
        icon: <UsersIcon className="w-5 h-5" />,
        link: '/student/all',
        activeNavString: '/student',
        subOptions: [],
        permission: permission.student
      },
      {
        name: 'Youtube',
        icon: <UsersIcon className="w-5 h-5" />,
        link: '/youtube/all',
        activeNavString: '/youtube',
        subOptions: [],
        permission: permission.youtube
      },
      {
        name: 'Teams',
        icon: <UserFriendIcon className="w-5 h-5" />,
        link: '/teammates/all',
        activeNavString: '/teammates',
        subOptions: [],
        permission: permission.team
      },

      // {
      //   name: 'Resources',
      //   icon: <BookIcon className="w-5 h-5" />,
      //   link: '/resources/all',
      //   activeNavString: '/resources',
      //   subOptions: [],
      //   permission: permission.resources
      // },
      {
        name: 'Subject',
        icon: <BookIcon className="w-5 h-5" />,
        link: '/subject/all',
        activeNavString: '/subject',
        subOptions: [],
        permission: permission.subject
      },
      {
        name: 'Questions',
        icon: <BookIcon className="w-5 h-5" />,
        link: '/question',
        activeNavString: '/question',
        subOptions: [],
        permission: permission.question
      },

      {
        name: 'Notification',
        icon: <BookIcon className="w-5 h-5" />,
        link: '/notification',
        activeNavString: '/notification',
        subOptions: [],
        permission: permission.notification
      },
      {
        name: 'Contact Us',
        icon: <PhoneAltIcon className="w-5 h-5" />,
        link: '/contactus',
        activeNavString: '/contactus',
        subOptions: [],
        permission: permission.contactus
      },
      {
        name: 'Settings',
        icon: <SettingIcon className="w-5 h-5" />,
        link: '/settings/general',
        activeNavString: '/settings',
        subOptions: [],
        permission: permission.settings
      }
    ],
    applications: [
      {
        name: 'Email',
        subOptions: [
          {
            name: 'Inbox',
            to: 'inbox'
          }
        ]
      }
    ]
  }
}

// export default SideDrawerOptions
// ccategoryReduceronst sideDrawerOptions = {
//   mainMenu: [
//     {
//       name: 'Dashboard',
//       icon: <Home className="w-5 h-5" />,
//       link: '/dashboard',
//       activeNavString: '/dashboard',
//       subOptions: [],
//     },
//     {
//       name: 'Faculty',
//       icon: <PeopleIcon className="w-5 h-5" />,
//       link: '/faculty/all',
//       activeNavString: '/faculty',
//       subOptions: [
//         {
//           name: 'Faculty',
//           to: '/faculty/all'
//         }
//       ]
//     },
//     {
//       name: 'Student',
//       icon: <UsersIcon className="w-5 h-5" />,
//       link: '/student/all',
//       activeNavString: '/student',
//       subOptions: []
//     },
//     {
//       name: 'Teams',
//       icon: <UserFriendIcon className="w-5 h-5" />,
//       link: '/teammates/all',
//       activeNavString: '/teammates',
//       subOptions: []
//     },

//     {
//       name: 'Subject',
//       icon: <BookIcon className="w-5 h-5" />,
//       link: '/subject/all',
//       activeNavString: '/subject',
//       subOptions: []
//     },
//     {
//       name: 'Questions',
//       icon: <BookIcon className="w-5 h-5" />,
//       link: '/question',
//       activeNavString: '/question',
//       subOptions: []
//     },
//     {
//       name: 'Contact Us',
//       icon: <PhoneAltIcon className="w-5 h-5" />,
//       link: '/contactus',
//       activeNavString: '/contactus',
//       subOptions: []
//     },

//     {
//       name: 'Settings',
//       icon: <SettingIcon className="w-5 h-5" />,
//       link: '/settings/general',
//       activeNavString: '/settings',
//       subOptions: []
//     }
//   ],
//   applications: [
//     {
//       name: 'Email',
//       subOptions: [
//         {
//           name: 'Inbox',
//           to: 'inbox'
//         }
//       ]
//     }
//   ]
// }

// export default sideDrawerOptions
