import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'static/svg/plus.svg'

import AddNewResource from './AddResources'

import { PopupMenu } from 'global/globalComponents'

import { ToggleSwitch } from 'global/globalComponents'
import { ReactComponent as DeleteIcon } from 'static/svg/trash.svg'
import { ReactComponent as EditIcon } from 'static/svg/vector.svg'

import Pill from 'global/globalComponents/Pill/Pill'
import DeleteModal from 'global/globalComponents/AlertModal/AlertModal'

import { OuterContentWrapper, SectionHeading } from 'global/globalComponents'

import { errorMessages } from 'utils'
import {
  Th,
  Thead,
  TableContainer,
  Tbody,
  Td,
  Tr
} from 'global/globalComponents/TailwindTable/TailwindTable'

import { TableDataLoading } from 'global/globalComponents/TailwindTable/TailwindTable'

import { deleteResources, fetchResources, fetchResourcesByCategory, updateResources } from 'thunks/resources/actions'
import ResourcesEditPanel from './ResourcesEditPanel'


import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import { format } from 'date-fns'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'


const Resources = () => {
  const dispatch = useDispatch()

  const {categoryId} = useParams();

  const resourcesData = useSelector((state) => state.resources)


  const [resources, setResources] = useState([])


  const superCategoryData = useSelector(state => state.examCategory)
  const categoryData = useSelector(state => state.exams)

  const permission = useSelector((state) => state.permission.data)

  const [resourcesAddPanel, setResourcesAddPanel] = useState(false)
  const [toggleSwitchLoading, setToggleSwitchLoading] = useState([])

  const [loading, setLoading] = useState(false)

  const [singleResourceData, setSingleResourceData] = useState({
    open: false,
    data: {},
    close: true
  })

  const [deleteResourcesData, setDeleteResourcesData] = useState({
    open: false,
    data: {},
    loading: false
  })


  useEffect(()=>{

    if(resourcesData.data.length){
      let finalData = []
       resourcesData.data.map(i => {
        finalData = [...finalData, ...i.data]
       })


      setResources(finalData)
    }
  },[resourcesData.data])

  useEffect(() => {
    if (!resourcesData.fetched) {
      dispatch(fetchResourcesByCategory(categoryId))
    }
  }, [resourcesData.fetched])


  const handleDeleteResources = (contact, e) => {
    setLoading(true)
    setDeleteResourcesData((prev) => ({ ...prev, loading: true }))

    dispatch(deleteResources(contact._id, deleteResourcesCallback))
  }

  const deleteResourcesCallback = (res, err) => {

    if (err) {
      setLoading(false)
      dispatch(fireErrorToaster(res.data.message))
    } else {
      setLoading(false)
      dispatch(fireSuccessToaster('Deleted successfully'))
    }
  }


  const handleClose = () => {
    setResourcesAddPanel(false)
  }

  const handleSidePanel = () => {
    setResourcesAddPanel(true)
  }

  const handleEditPanelOpen = (item) => {
    if (singleResourceData.open) {
      setSingleResourceData({
        open: false,
        data: {}
      })
    } else {
      setSingleResourceData({
        open: true,
        data: item
      })
    }
  }

  const handleDeleteResourcesFn = (teammate) => {
    if (deleteResourcesData.open) {
      setDeleteResourcesData({
        open: false,
        data: {},
        loading: false
      })
    } else {
      setDeleteResourcesData({
        open: true,
        data: teammate,
        loading: false
      })
    }
  }

  const handleEditPanelClose = () => {
    setSingleResourceData({ open: false, data: {} })
  }

  const handleSwitch = (item) => {
    setToggleSwitchLoading((prevState) => [...prevState, item._id])
    dispatch(
      updateResources(item._id, { isActive: !item.isActive }, switchCallback)
    )
  }
  const switchCallback = (res, err) => {
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      dispatch(fireSuccessToaster(errorMessages.CHANGES_SAVED_MESSAGE))
      setToggleSwitchLoading((prevState) =>
        prevState.filter((item) => res._id !== item)
      )
    }
  }

  return (
    <OuterContentWrapper>
      <div>
        <div className="flex justify-between items-center mb-9">
          <SectionHeading>Resources</SectionHeading>
          {permission[0]?.examCategory?.create && (
            <Button
              variant="outlined"
              startIcon={<AddIcon fontSize="small" />}
              onClick={handleSidePanel}
            >
              Add Resources
            </Button>
          )}
        </div>
        <TableContainer mb={9}>
          <Thead>
            <Th className="w-44 whitespace-nowrap">Name</Th>

            <Th className="text-center">Status</Th>

            <Th className="text-center">Views</Th>


            <Th className="w-48 text-center whitespace-nowrap">Created At</Th>
            {(permission[0]?.examCategory?.update ||
              permission[0]?.examCategory?.delete) && (
                <Th className="whitespace-nowrap text-center">More Options</Th>
              )}
          </Thead>

          <Tbody>
            {resourcesData.loading ? (
              <TableDataLoading cols={5} />
            ) : resources.length > 0 ? (
              resources.map((item, index) => (
                <Row
                  key={index}
                  item={item}
                  handleEditPanelOpen={handleEditPanelOpen}
                  handleDeleteResources={handleDeleteResources}
                  permission={permission}
                  loading={loading}
                  handleSwitch={handleSwitch}
                  toggleSwitchLoading={toggleSwitchLoading}
                />
              ))
            ) : (
              <Tr className="bg-white">
                <th
                  colSpan="9"
                  className="text-left text-sm py-3 px-6 text-gray-500 font-normal"
                >
                  There are resources
                </th>
              </Tr>
            )}
          </Tbody>
        </TableContainer>
        <AddNewResource open={resourcesAddPanel} onClose={handleClose} />

        <ResourcesEditPanel
          open={singleResourceData.open}
          onClose={handleEditPanelClose}
          data={singleResourceData.data}
        />
        <DeleteModal
          warningText=" will be deleted. Do you want to proceed ?"
          open={deleteResourcesData.open}
          handleDialog={handleDeleteResourcesFn}
          handleDeleteAction={handleDeleteResources}
          loading={deleteResourcesData.loading}
        />
      </div>
    </OuterContentWrapper>
  )
}

const Row = ({
  item,
  handleSwitch,
  handleEditPanelOpen,
  handleDeleteResources,
  toggleSwitchLoading,
  permission
}) => {
  const openEditTeammatePanel = () => handleEditPanelOpen(item)
  const handleDelete = () => handleDeleteResources(item)

  return (
    <Tr key={item._id}>
      
      <Td onClick={null} className="whitespace-nowrap text-left cursor-pointer">
        <div className="flex flex-col ">
          <span className="text-primary-mid-dark font-medium text-sm">

            {item.name ?? "Prevous Year Paper"}
          </span>
          
          <span className="text-primary-mid-dark-2 text-xs">Held On: {item.heldOn}, Stage: {item.tier}, shift: {item.shift}, Year: {item.year}</span>
        </div>
      </Td>

    

      <Td className="whitespace-nowrap">
        <ToggleSwitch
          style={{ margin: '0' }}
          checked={item.isActive}
          onChange={() => handleSwitch(item)}
          loading={toggleSwitchLoading.includes(item._id)}
        />
      </Td>

      <Td className="whitespace-wrap text-left">
        <div className="flex flex-col ">
         
          <span className="text-primary-mid-dark-2 text-xs text-center">{item.viewCount}</span>
        </div>
      </Td>

      <Td className="whitespace-nowrap text-center">
        <span className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          <svg
            className="mr-1 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {item.createdAt ? format(new Date(item.createdAt), 'd MMM p') : '-'}
        </span>
      </Td>

      {(permission[0]?.examCategory.update || permission[0]?.examCategory.delete) && (
        <Td className=" whitespace-nowrap text-center">
          <PopupMenu
            menuItems={[
              {
                label: 'Edit',
                method: openEditTeammatePanel,
                icon: <EditIcon className="text-primary-main" />
              },
              // {
              //   label: 'Settings',
              //   method: OpenSingleClientPage,
              //   icon: <SettingIcon className="text-primary-main" />
              // },
              // {
              //   label: 'Delete',
              //   method: handleDelete,
              //   icon: <DeleteIcon className="text-error-main" />
              // }
            ]}
          />
        </Td>
      )}

    </Tr>
  )
}

export default Resources
