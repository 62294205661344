import React, { useState } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import FormLabel from 'global/globalComponents/FormLabel/FormLabel'

import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import Select from 'react-select'

import ResourcesStyles from './ResourcesStyles'

import { fetchAllExamCategory } from "thunks/examCategory/actions"


import { fetchAllExam } from "thunks/exam/actions"

import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'


import { addResources } from 'thunks/resources/actions'
import { LoadingButton } from 'global/globalComponents'
import { useEffect } from 'react'

const AddNewResource = (props) => {

  const { open, onClose } = props

  const classes = ResourcesStyles()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const [resourceData, setResourceData] = useState({

    name: "",
    superCategory: "",
    category: "",
    year: "",
    stage: "",
    shift: "",
    heldOn: "",
    resources: [
      {
        language: "English",
        name: "",
        url: ""
      },
      {
        language: "Hindi",
        name: "",
        url: ""
      },
    ],
    isActive: true
  })


  const superCategoryData = useSelector(state => state.examCategory)
  const allCategoryData = useSelector(state => state.exams)

  const [superCategoryOptions, setSuperCategoryOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])

  const [selectedSuperCategory, setSelectedSuperCategory] = useState({})
  const [selectedCategory, setSelectedCategory] = useState({})

  useEffect(() => {
    if (!superCategoryData?.fetched) {
      dispatch(fetchAllExamCategory())
    }

    // if (!allCategoryData?.data?.length) {
    //   dispatch(fetchAllExam())
    // }

  }, [])


  useEffect(() => {
    // const temp = superCategoryData.data.map(i => ({ label: i.name, value: i._id }))
    // setSuperCategoryOptions(temp)

    if(superCategoryData?.currentSuperCategory?._id){
      setResourceData(state => ({...state, superCategory: superCategoryData.currentSuperCategory._id}))
    }
  }, [superCategoryData.fetched])


  useEffect(() => {
    if(allCategoryData?.currentCategory?._id){
      setResourceData(state => ({...state, category: allCategoryData.currentCategory._id}))
    }
  }, [allCategoryData])


  // useEffect(() => {
  //   if (resourceData.superCategory) {
  //     setCategoryOptions()
  //   }

  //   let temp = allCategoryData.data.filter(state => state.superCategory.includes(resourceData.superCategory))

  //   temp = temp.map(i => ({ label: i.name, value: i._id }))

  //   setCategoryOptions(temp)

  // }, [resourceData.superCategory])

  const formInputHandler = (e) => {

    const { value, name } = e.target

    if (name === "englishFileUrl" || name === "englishFileName") {
      const newResources = [...resourceData.resources];

      newResources[0] = { ...newResources[0], [name === "englishFileUrl" ? "url" : "name"]: value , url:`/pdf/ssc-chsl/2023/${value}.pdf` }

      setResourceData((prevState) => ({ ...prevState, resources: newResources }))

    } else if (name === "hindiFileUrl" || name === "hindiFileName") {
      const newResources = [...resourceData.resources];
      newResources[1] = { ...newResources[1], [name === "hindiFileUrl" ? "url" : "name"]: value };
      setResourceData((prevState) => ({ ...prevState, resources: newResources }))
    }else {
      setResourceData((prevState) => ({ ...prevState, [name]:   name === 'year' ? parseInt(value, 10) : value }))

    }
  }

  const handleButtonClick = () => {

    if(!resourceData.superCategory || !resourceData.category){
      alert("SuperCategory/Category Missing. Please refresh and try again")
    }else{
      setLoading(true)
      dispatch(
        addResources(resourceData, (res, err) => {
          addResourceCallback(res, err)
        })
      )
    }
  }

  const addResourceCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {

        name: "",
        superCategory: "",
        category: "",
        year: " ",
        stage: " ",
        shift: "",
        heldOn: "",
        resources: [
          {
            language: "English",
            name: "",
            url: ""
          },
          {
            language: "Hindi",
            name: "",
            url: ""
          },
        ],
        isActive: true
      }
      setResourceData(data)

      dispatch(fireSuccessToaster('Exam added successfully!'))
      closeSidePanel()
    }
  }


  const handleChange = (newVal, e) => {
    const { name, option } = e

    if (name === "superCategory") {
      setResourceData(prev => ({ ...prev, [name]: newVal.value }))
      setSelectedSuperCategory(newVal)
    } else if (name === "category") {
      setResourceData(prev => ({ ...prev, [name]: newVal.value }))
      setSelectedCategory(newVal)
    }

  }

  const closeSidePanel = () => {
    onClose()
    setResourceData({

      name: "",
      superCategory: "",
      category: "",
      year: "",
      stage: " ",
      shift: "",
      heldOn: "",
      resources: [
        {
          language: "English",
          name: "",
          url: ""
        },
        {
          language: "Hindi",
          name: "",
          url: ""
        }
      ],
      isActive: true
    })
  }

  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={closeSidePanel}>Add Resources</DrawerHeader>
        <DrawerContent open={open} style={{ marginBottom: "30px" }}>

          <div>


            <div className="flex mb-2 mt-2 text-xs">

              <Box mt={2} mr={2} className="flex-1 ">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={resourceData.name}
                  onChange={formInputHandler}
                />
              </Box>

              <Box mt={2} className="flex-1 ">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="heldOn"
                  type="text"
                  placeholder="Held On (DD/MM/YYY)"
                  value={resourceData.heldOn}
                  onChange={formInputHandler}
                />
              </Box>
            </div>



            <div className="flex mb-2 mt-2 text-xs">
              <Box mt={2} mr={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="year"
                  type="number"
                  placeholder="Year"
                  value={resourceData.year}
                  onChange={formInputHandler}
                />
              </Box>
              <Box mt={2} mr={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="shift"
                  type="number"
                  placeholder="Shift"
                  value={resourceData.shift}
                  onChange={formInputHandler}
                />
              </Box>

              <Box mt={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="stage"
                  type="text"
                  placeholder="Stage"
                  value={resourceData.stage}
                  onChange={formInputHandler}
                />
              </Box>
            </div>
            {/* <div className="flex mb-2 mt-2 text-xs">
              <Box mt={2} mr={2} className="flex-1">
                <Select
                  options={superCategoryOptions}
                  value={selectedSuperCategory}
                  styles={reactSelectCustomStyles}
                  placeholder="Super Category"
                  className="w-full"
                  name="superCategory"
                  onChange={handleChange}
                />
              </Box>
              <Box mt={2} className="flex-1">
                <Select
                  options={categoryOptions}

                  value={
                    selectedCategory
                  }
                  styles={reactSelectCustomStyles}
                  placeholder="Category "
                  className="w-full"
                  name="category"
                  onChange={handleChange}
                />
              </Box>
            </div> */}





            <Box mt={2} className="flex-1">
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="englishFileName"
                type="text"
                placeholder="English File Name"
                value={resourceData.resources[0].name}
                onChange={formInputHandler}
              />
            </Box>

            <Box mt={2} className="flex-1">
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="englishFileUrl"
                type="text"
                placeholder="English File Url"
                value={resourceData.resources[0].url}
                onChange={formInputHandler}
              />
            </Box>


            {/* <Box mt={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="hindiFileName"
                  type="text"
                  placeholder="Hindi File Name"
                  value={resourceData.resources[1].name}
                  onChange={formInputHandler}
                />
              </Box>

              <Box mt={2} className="flex-1">
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="hindiFileUrl"
                  type="text"
                  placeholder="Hindi File Url"
                  value={resourceData.resources[1].url}
                  onChange={formInputHandler}
                />
              </Box> */}

          </div>

        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewResource)
