import axios from 'axios'
import { EXAM } from './reducers'
import { errorMessages } from 'utils'

export const addExam = (data, callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/category',
        data
      })
      dispatch({ type: EXAM.ADD_ONE_EXAM, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      if (callback)
        callback(
          err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE,
          true
        )
      dispatch({
        type: EXAM.ERROR,
        payload: err?.response?.data?.message ?? errorMessages.ERROR_MESSAGE
      })
    }
  }
}

export const fetchAllExam = async (callback) => {
  // return async (dispatch) => {
    // dispatch({ type: STUDENT.LOADING })
    try {
      const res = await axios({
        method: 'GET',
        url: '/category'
      })

      // dispatch({ type: EXAM.FETCHED_ALL_CATEGORY, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      // dispatch({ type: EXAM.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  // }
}

export const fetchCategoryBySuperCategory = (superCategoryId, query,callback) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `/category/superCategory/${superCategoryId}?page=${query.page}&limit=${query.limit}`
      })


      dispatch({ type: EXAM.FETCHED, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: EXAM.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}

export const updateCurrentCategory = (id, callback) => {
  return async (dispatch) => {
    try {
      dispatch({ type: EXAM.UPDATE_CURRENT_CATEGORY, payload: {_id: id} })
      if (callback) callback(id, false)
    } catch (err) {
      console.log(err)
      dispatch({ type: EXAM.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}

/**
 * update Teamamte isACtive status.
 * @param {id} id Teammate id
 * @param {Object} data isACtive
 * @param {Function} callback callback function
 */
export const updateExam = (id, data, callback) => {
  return async (dispatch) => {
    // dispatch({ type: EXAM.LOADING })
    try {
      const res = await axios({
        method: 'PUT',
        url: `/category/${id}`,
        data
      })
      dispatch({ type: EXAM.UPDATE_ONE_EXAM, payload: res.data })
      if (callback) callback(res.data, false)
    } catch (err) {
      console.log(err)
      // dispatch({type: , payload:err.response.data.message})
      if (callback) callback(err.response, true)
    }
  }
}

export const deleteExam = (exam, callback) => {
  return async (dispatch) => {
    // dispatch({ type: EXAM.LOADING })

    try {
      const res = await axios({
        method: 'DELETE',
        url: `/category/${exam._id}`
      })

      if (callback) callback(res, false)
      dispatch({ type: EXAM.DELETE_ONE_EXAM, payload: res.data })
    } catch (err) {
      dispatch({ type: EXAM.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}

export const resetExam = (callback) => {
  return async (dispatch) => {
    try {
      if (callback) callback([], false)
      dispatch({ type: EXAM.RESET_EXAM, payload: [] })
    } catch (err) {
      dispatch({ type: EXAM.ERROR, payload: err?.response?.data?.message })
      if (callback) callback(err?.response?.data?.message, true)
    }
  }
}
