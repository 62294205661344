import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, TextField } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import {
  fireErrorToaster,
  fireSuccessToaster
} from 'thunks/fireToaster/actions'

import FormLabel from 'global/globalComponents/FormLabel/FormLabel'

import Select from 'react-select'
import { fetchTopicsBySubjectId } from 'thunks/topic/actions'

import {
  LoadingButton,
  ToggleSwitch,
  Pill,
  CustomTooltip
} from 'global/globalComponents'
import CustomDrawer, {
  DrawerContent,
  DrawerFooter,
  DrawerHeader
} from 'global/globalComponents/CustomDrawer/CustomDrawer'

import reactSelectCustomStyles from 'global/stlyeClasses/reactSelectStyles'
import { ReactComponent as TrashIcon } from 'static/svg/trash.svg'


import ExamStyles from './ExamStyles'

import { fetchAllExamCategory } from 'thunks/examCategory/actions'

import { fetchSubject } from "thunks/subject/actions"

import { addExam } from 'thunks/exam/actions'

// import { fetchAllSubject } from 'thunks/subject/actions'

const AddNewExam = (props) => {
  const { open, onClose } = props
  const classes = ExamStyles()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const allExamCategory = useSelector(state => state.examCategory)
  const subjectData = useSelector((state) => state.subject)
  const [toggleSwitch, setToggleSwitch] = useState(false)
  // var [subjectAndTopicsList, setSubjectAndTopicsList] = useState([])
  const [examData, setExamData] = useState({
    icon: "",
    code: '',
    name: '',
    examCategory: [],
    metadata: [],
    totalQuestions: null,
    // hasSubjectWiseQuestions: false,
    // hasSubjectWiseTime: false,
    totalTimeInMinutes: null,
    isActive: false
  })

  const [examCategoryOptions, setExamCategoryOptions] = useState({
    loading: true,
    data: []
  })
  const [subjectOptions, setSubjectOptions] = useState({
    loading: true,
    data: []
  })
  const [selectedSubject, setSelectedSubject] = useState([])

  // const [topicOptions, setTopicOptions] = useState({
  //   loading: true,
  //   data: []
  // })
  // const [selectedTopic, setSelectedTopic] = useState([])


  useEffect(() => {
    if (!allExamCategory.fetched) {
      dispatch(fetchAllExamCategory())
    }
    setExamCategoryOptions(prev => ({ ...prev, loading: false, data: allExamCategory.data.map((item) => ({ label: item.name, value: item._id })) }))
  }, [allExamCategory])

  useEffect(() => {

    if (subjectData.data.length == 0) {

      dispatch(fetchSubject())
    } else {

      setSubjectOptions(prevState => ({
        ...prevState,
        loading: false,
        data: subjectData.data.map((subject) => ({
          value: subject._id,
          label: subject.name
        }))
      }))
    }
  }, [subjectData])


  // useEffect(() => {
  //   if (selectedSubject.value) {
  //     setTopicOptions({ loading: true, data: [] })

  //     dispatch(
  //       fetchTopicsBySubjectId(selectedSubject.value, (res, err) => {
  //         if (res) {
  //           setTopicOptions((prev) => ({
  //             ...prev,
  //             loading: false,
  //             data: res.map((item) => ({ label: item.name, value: item._id }))
  //           }))
  //         } else {
  //           setTopicOptions((prev) => ({ ...prev, loading: false }))
  //         }
  //       })
  //     )
  //   }
  // }, [selectedSubject.value])



  const formInputHandler = (e) => {
    const { value, name } = e.target

    setExamData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleButtonClick = () => {
    if (
      !examData.name ||
      !examData.totalTimeInMinutes ||
      !examData.totalQuestions
      // || subjectAndTopicsList.length == 0
    ) {
      return dispatch(fireErrorToaster('Please fill all the required fields!'))
    }

    var randomNumber = Math.floor(1000 + Math.random() * 9000)

    let data = {
      ...examData,
      code: `CAT-${randomNumber}`,
      isActive: toggleSwitch,

      metadata: selectedSubject.map(val => {
        const currentSubject = subjectData.data.find(i => i._id == val.value)

        return {
          subjectId: val.value,
          name: val.key,
          types: currentSubject?.types ?? [],
          topics: []
        }
      }),

      // content: subjectAndTopicsList.map(subject => ({
      //   subject: subject.subject.value,
      //   topics: subject.topics.map(topic => topic.value)
      // }))
    }

    setExamData(data)
    setLoading(true)

    dispatch(
      addExam(data, (res, err) => {
        addExamCallback(res, err)
      })
    )
  }

  const addExamCallback = (res, err) => {
    setLoading(false)
    if (err) {
      dispatch(fireErrorToaster(res))
    } else {
      let data = {
        icon: "",
        code: '',
        name: '',
        isActive: false,
        examCategory: [],
        content: [],
        totalQuestions: null,
        // hasSubjectWiseQuestions: false,
        // hasSubjectWiseTime: false,
        totalTimeInMinutes: null,
        metadata: []
      }
      setExamData(data)
      setSubjectOptions({ loading: true, data: [] })
      setSelectedSubject([])
      // setTopicOptions({ loading: true, data: [] })
      // setSelectedTopic([])
      setToggleSwitch(false)

      dispatch(fireSuccessToaster('Exam added successfully!'))
      closeSidePanel()
    }
  }

  const closeSidePanel = () => {
    onClose()
    setExamData({
      icon: "",
      code: '',
      name: '',
      isActive: false,
      // content: [],
      totalQuestions: null,
      // hasSubjectWiseQuestions: false,
      // hasSubjectWiseTime: false,
      totalTimeInMinutes: null,
      metadata: []

    })

    setSubjectOptions({ loading: true, data: [] })
    setSelectedSubject([])
    // setTopicOptions({ loading: true, data: [] })
    // setSelectedTopic([])
    setToggleSwitch(false)
  }

  const handleSwitch = () => {
    setToggleSwitch((prev) => !prev)
  }

  const handleExamCategoryChange = (newVal) => {
    setExamData((oldState) => ({
      ...oldState,
      examCategory: newVal.map(item => item.value)
    }))
  }

  const handleSelectSection = (e) => {


    setSelectedSubject(e)
  }

  // const handleSelectTopic = (e) => {
  //   setSelectedTopic(e)
  // }


  // const handleAddNewSubjectAndTopic = () => {

  //   setSubjectAndTopicsList(prev => [...prev, {
  //     metadata: selectedSubject,
  //     topics: selectedTopic,
  //     totalQuestions: null,
  //     totalTimeInMinutes: null
  //   }
  //   ])

  //   setSubjectOptions(prev => ({
  //     ...prev,
  //     data: subjectOptions.data.filter(subject => subject.value !== selectedSubject.value)
  //   }))

  //   setSelectedSubject({})
  //   setSelectedTopic([])
  // }


  // const handleUpdateSubjectFromList = (index, e) => {
  //   const { value, name } = e.target
  //   subjectAndTopicsList[index][name] = value
  //   setSubjectAndTopicsList([...subjectAndTopicsList])
  // }

  // const handleDeleteSubjectFromList = (value) => {
  //   const leftSubject = subjectAndTopicsList.filter((item, index) => index !== value)
  //   setSubjectAndTopicsList(leftSubject)

  //   var deletedSubject = subjectAndTopicsList.find((item, index) => index === value)
  //   setSubjectOptions(prev => ({
  //     ...prev,
  //     data: [
  //       ...prev.data, deletedSubject.subject
  //     ]
  //   }))
  // }

  // const handleChangeCheckbox = (e) => {
  //   setExamData((oldState) => ({
  //     ...oldState,
  //     [e.target.name]: e.target.checked
  //   }))
  // }


  return (
    <>
      <CustomDrawer
        onClose={closeSidePanel}
        anchor="right"
        open={open}
        variant="persistent"
      >
        <DrawerHeader onClose={closeSidePanel}>Add Exam</DrawerHeader>
        <DrawerContent open={open}>

          <div>

            <Box mt={2}>
              <FormLabel required>Exam Icon Url</FormLabel>
              <TextField
                className={classes.textFieldStyle}
                inputProps={{
                  autoComplete: 'off'
                }}
                name="icon"
                type="text"
                placeholder="Exam Icon Url"
                value={examData.icon}
                onChange={formInputHandler}
              />
            </Box>


            <div className="flex space-x-4">

              <Box mt={2} className="flex-1">
                <FormLabel required>Exam Name</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="name"
                  type="text"
                  placeholder="Exam Name"
                  value={examData.name}
                  onChange={formInputHandler}
                />
              </Box>
            </div>



            <Box mt={2}>
              <FormLabel>Category</FormLabel>
              <Select
                className="col-span-4 md:col-span-2 text-sm"
                placeholder="Category"
                isSearchable={true}
                options={examCategoryOptions.data}
                onChange={handleExamCategoryChange}
                isMulti
                styles={reactSelectCustomStyles}
                required
              />
            </Box>

            <div className="flex justify-between items-center space-x-4">
              <Box mt={2} className="flex-1">
                <FormLabel required>Total Questions</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="totalQuestions"
                  type="number"
                  placeholder="Total Questions"
                  value={examData.totalQuestions}
                  onChange={formInputHandler}
                  required
                />
              </Box>

              <Box mt={2} className="flex-1">
                <FormLabel required>Total Time (in Minutes)</FormLabel>
                <TextField
                  className={classes.textFieldStyle}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  name="totalTimeInMinutes"
                  type="number"
                  placeholder="Enter Exam Time in Minutes (eg. 60)"
                  value={examData.totalTimeInMinutes}
                  onChange={formInputHandler}
                  required
                />
              </Box>

            </div>

            {/* <Box className="flex justify-start items-center mt-2 ">
              <Checkbox
                name="hasSubjectWiseQuestions"
                checked={examData.hasSubjectWiseQuestions}
                onChange={handleChangeCheckbox}
              />
              <FormLabel required>Subject wise questions</FormLabel>
            </Box> */}


            {/* <Box className="flex justify-start items-center mt-2">
              <Checkbox
                name="hasSubjectWiseTime"
                checked={examData.hasSubjectWiseTime}
                onChange={handleChangeCheckbox}
              />
              <FormLabel required>Subject wise Time</FormLabel>
            </Box> */}

            <Box mt={2} style={{ marginTop: "20px" }}>
              <FormLabel required>Subject :</FormLabel>
              <Select
                styles={reactSelectCustomStyles}
                placeholder="Select Subject"
                options={subjectOptions.data}
                value={selectedSubject.length ? selectedSubject : []}
                className="w-full"
                name="subject"
                isMulti
                onChange={handleSelectSection}
                isLoading={subjectOptions.loading}
              />
            </Box>

            {/* {
              selectedSubject.value && (

                <div>
                  <Box mt={2}>
                    <FormLabel required>Topics</FormLabel>
                    <Select
                      styles={reactSelectCustomStyles}
                      placeholder="Select Topics"
                      options={topicOptions.data}
                      value={selectedTopic.length ? selectedTopic : null}
                      className="w-full"
                      name="topics"
                      onChange={handleSelectTopic}
                      isMulti
                      isLoading={topicOptions.loading}
                    />
                  </Box>

                  {
                    selectedTopic.length > 0 && (
                      <Box mt={2} textAlign='right' className="space-y-8"   >
                        <Button
                          variant="contained"
                          onClick={handleAddNewSubjectAndTopic}
                          size="medium"
                        >
                          Add Next Subject
                        </Button>

                      </Box>
                    )
                  }
                </div>
              )
            } */}



            <Box className="flex items-center justify-between mt-3">
              <p className="text-sm font-medium text-gray-700">
                Publish this exam{' '}
              </p>

              <div className="flex items-center gap-1">
                <span className="text-sm font-medium text-gray-700">No</span>
                <ToggleSwitch
                  checked={toggleSwitch}
                  onChange={handleSwitch}
                  disableRipple={true}
                />
                <span className="text-sm font-medium text-gray-700">Yes</span>
              </div>
            </Box>



          </div>
          {/* <div className="mt-3">
            {subjectAndTopicsList.length > 0 && <p style={{ color: "red", fontWeight: "bold" }}>Selected Subject & Topics : </p>}
            {subjectAndTopicsList?.map((item, index) => (
              <li className={'flex items-center py-2 '} key={index}>
                <span className="mr-2 text-sm">{index + 1}.</span>
                <div className="flex items-center">
                  <div>
                    <span className="block text-sm font-medium">
                      {item.subject.label}
                    </span>
                  </div>
                  <div className="ml-2">
                    {item.topics.map((topic, index) => (
                      <Pill variant="blue">{topic.label}</Pill>
                    ))
                    }
                  </div>
                </div>

                <div className="flex justify-between">
                  {

                    examData?.hasSubjectWiseQuestions && (
                      <TextField
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        style={{ width: '30%' }}
                        name="totalQuestions"
                        type="number"
                        variant="outlined"
                        // size="small"
                        placeholder="Total Questions"
                        // value={examData.totalQuestions}
                        onChange={(e) => handleUpdateSubjectFromList(index, e)}
                        required
                      />
                    )
                  }

                  {
                    examData?.hasSubjectWiseTime && (
                      <TextField
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        style={{ width: '50%' }}
                        name="total Time"
                        type="number"
                        variant="outlined"
                        // size="small"
                        placeholder="Total Time (minutes)"
                        // value={examData.totalQuestions}
                        // onChange={formInputHandler}
                        required
                      />
                    )
                  }
                </div>
                <div className="ml-auto leading-3 flex space-x-4">
                  <CustomTooltip title="Remove" placement="top">
                    <button
                      className="text-custom-red-main opacity-60 hover:opacity-100"
                      onClick={() => handleDeleteSubjectFromList(index)}
                      data-id={index}
                    >
                      <TrashIcon />
                    </button>
                  </CustomTooltip>
                </div>
              </li>
            ))}
          </div> */}
        </DrawerContent>
        <DrawerFooter className={classes.panelFooter}>
          <Button
            variant="outlined"
            onClick={closeSidePanel}
            className="w-full"
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            onClick={handleButtonClick}
            className="w-full"
          >
            Add
          </LoadingButton>
        </DrawerFooter>
      </CustomDrawer>
    </>
  )
}

export default React.memo(AddNewExam)
