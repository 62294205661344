const initialState = {
  loading: true,
  error: false,
  data: [],
  fetched: false
}

export const TOPICS = {
  LOADING: 'TOPICS_LOADING',
  ERROR: 'TOPICS_ERROR',
  FETCHED: 'TOPICS_FETCHED',
  UPDATED: 'TOPICS_UPDATED',
  ADD_ONE_TOPICS: 'TOPICS_ADD_ONE_TOPICS',
  UPDATE_ONE_TOPICS: 'UPDATE_ONE_TOPICS',
  DELETE_ONE_TOPICS: 'DELETE_ONE_TOPICS',
  RESET_TOPICS: 'RESET_TOPICS'
}

const topicReducer = (state = initialState, action) => {
  const getCurrentIndex = (id) => state.data.findIndex((i) => i._id === id)

  switch (action.type) {
    case TOPICS.LOADING:
      return { ...state, loading: true, error: false, fetched: false }

    case TOPICS.ERROR:
      return { ...state, loading: false, error: action.payload, fetched: true }

    case TOPICS.FETCHED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case TOPICS.UPDATED:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        fetched: true
      }

    case TOPICS.ADD_ONE_TOPICS:
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        data: [...state.data, action.payload]
      }

    case TOPICS.UPDATE_ONE_TOPICS:
      const updatedData1 = [...state.data]
      updatedData1[getCurrentIndex(action.payload._id)] = action.payload
      return {
        ...state,
        data: updatedData1,
        fetched: true
      }

    case TOPICS.DELETE_ONE_TOPICS:
      return {
        ...state,
        loading: false,
        error: false,
        fetched: true,
        data: state.data.filter((item) => item._id !== action.payload._id)
      }

      case TOPICS.RESET_TOPICS:
        return {
          ...state,
          ...initialState
        }
    default:
      return state
  }
}

export default topicReducer
